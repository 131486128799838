// Generated i18n file
export default {
  unit: {
    AGE_ADULT_AND_CHILD: '大人・子供共通（{n}-{m}）歳',
    AGE_ADULT: '大人（{n}-{m}）歳',
    AGE_YOUTH: '中人（{n}-{m}）歳',
    AGE_CHILD: '子供（{n}-{m}）歳',
    AGE_SENIOR: 'シニア（{n}-{m}）歳',
    AGE_INFANT: '幼児（{n}-{m}）歳',
    AGE_STUDENT: '学生（{n}-{m}）歳',
    AGE_HIGH_SCHOOL: '高校生（{n}-{m}）歳',
    AGE_INTERMEDIATE_SCHOOL: '中学生（{n}-{m}）歳',
    AGE_ELEMENTALY_SCHOOL: '小学生（{n}-{m}）歳',
    AGE_DISABLED_ADULT: '障がい者-大人（{n}-{m}）歳',
    AGE_DISABLED_YOUTH: '障がい者-中人（{n}-{m}）歳',
    AGE_DISABLED_CHILD: '障がい者-子供（{n}-{m}）歳',
    AGE_SUPPORTER_ADULT: '介護者（大人）',
    AGE_SUPPORTER_CHILD: '介護者（子供）',
    MINUTES_VEHICLES: '{n} 分間',
    HOURS_VEHICLES: '{n} 時間',
    DAYS_VEHICLES: '{n} 日間',
    WEEKS_VEHICLES: '{n} 週間',
    NIGHTS_VEHICLES: '{n} 泊',
    CHARTER_VAR: '1台（{n}-{m}）人',
    GROUP_VAR: '1グループ（{n}-{m}）人',
    ROOM_VAR: '1室（{n}-{m}）人',
    BOAT_VAR: '1艇（{n}-{m}）人',
    PET: 'ペット',
    PET_CAT: '猫',
    PET_DOG: '犬',
    PET_DOG_S: '小型犬',
    PET_DOG_M: '中型犬',
    PET_DOG_L: '大型犬',
    PET_DOG_XL: '超大型犬',
    PET_OTHER: 'その他ペット'
  },
  collectingType: {
    PER_PARTICIPANT: '参加者ごと',
    PER_BOOKING: '予約ごと'
  },
  collectingTiming: {
    ON_BOOKING: '予約時に必要',
    BEFORE_ACTIVITY: '参加日までに必要',
    OPTIONAL: '任意'
  },
  formType: {
    DATE_ONLY_PAST: '日付（過去のみ）',
    DATE_ONLY_FUTURE: '日付（未来のみ）',
    TIME: '時間',
    YES_NO: 'はい/いいえ',
    DROPDOWN: 'ドロップダウン選択',
    RADIO: 'ラジオボタン',
    CHECK: 'チェックボックス',
    TEXT: 'テキスト',
    TEXT_ALPHANUMERIC: 'テキスト（英数字）',
    TEXT_PHONE: '電話番号',
    TEXTAREA: 'テキストエリア'
  },
  formatType: {
    BEFORE_ACTIVITY_FORWARD_FIXED_CLOCK:
      '利用/参加日{D}日前の現地時間{HH:MM}以降のキャンセルは、予約総額の{N}のキャンセル料が発生いたします。',
    BEFORE_ACTIVITY_FORWARD_DURATION:
      '利用/参加日時の{T}時間前以降のキャンセルは、予約総額の{N}のキャンセル料が発生いたします。',
    AFTER_BOOKING_FORWARD_DURATION:
      '予約確定後の{T}時間以降、予約総額の{N}のキャンセル料が発生いたします。',
    AFTER_BOOKING_FORWARD_FIXED_CLOCK:
      '予約確定日を0日目として、{D}日目の現地時間{HH:MM}以降、予約総額の{N}のキャンセル料が発生いたします。',
    BEFORE_ACTIVITY_BACKWARD_FIXED_CLOCK:
      '利用/参加日の{D}日前の現地時間{HH:MM}まで、予約総額の{N}のキャンセル料が発生いたします。',
    BEFORE_ACTIVITY_BACKWARD_DURATION:
      '利用/参加日時の{T}時間前まで、予約総額の{N}のキャンセル料が発生いたします。',
    AFTER_BOOKING_BACKWARD_DURATION:
      '予約確定後の{T}時間前まで、予約総額の{N}のキャンセル料が発生いたします。',
    AFTER_BOOKING_BACKWARD_FIXED_CLOCK:
      '予約確定日を0日目として、{D}日目の現地時間{HH:MM}まで、予約総額の{N}のキャンセル料が発生いたします。'
  },
  confirmationType: {
    FREE_SALE: '参加日指定（フリーセル）',
    FREE_SALE_OPEN_DATE: '参加日指定不要（フリーセル）',
    INVENTORY: '参加日指定（リアルタイム）',
    REQUEST: '参加日指定要（リクエスト）'
  },
  voucherType: {
    NONE: '不要',
    COLLECTIVE: '予約ごとのQRコード',
    INDIVIDUAL: '1人1つのQRコード'
  },
  voucherVersion: {
    VOUCHER_VERSION_V1: 'V1',
    VOUCHER_VERSION_V2: 'V2'
  },
  qrcodeType: {
    QRCODE: 'QRCODE',
    TEXT: 'TEXT',
    URL: 'URL',
    BARCODE_128: 'BARCODE_128',
    BARCODE_93: 'BARCODE_93',
    BARCODE_39: 'BARCODE_39',
    BARCODE_25: 'BARCODE_25'
  },
  qrcodeDisplayLocation: {
    QR_CODE_DISPLAY_LOCATION_VOUCHER: 'バウチャー',
    QR_CODE_DISPLAY_LOCATION_ETICKET_PORTAL_SITE: 'Eチケット表示サイト'
  },
  voucherMedium: {
    ELECTRONIC: '印刷不要（画面提示）',
    PAPER: '印刷必須'
  },
  cancellationFeeType: {
    PERCENTAGE: 'パーセンテージ',
    ADVANCED_PERCENTAGE: '高度なパーセンテージ設定',
    FIXED: '定額料金',
    NOT_CANCELLABLE: 'キャンセル不可'
  },
  datePeriodUnit: {
    DAY: '日計算',
    MONTH: '月計算'
  },
  basePrice: {
    GROSS: '販売価格',
    NET: '卸価格',
    SUPPLIER_NET: '催行会社卸価格'
  },
  checklistType: {
    RESTRICTION: '参加制限',
    REQUIRED_ITEM_TO_BRING_AND_ATTIRE: '参加時必要な服装・持ち物',
    REQUIRED_OTHER: '参加時に必須のその他のこと',
    REQUIRED_ADDITIONAL_NOTE: '備考・その他',
    NICE_TO_HAVE_ITEM_TO_BRING_AND_ATTIRE: 'おすすめの服装や持ち物',
    NICE_TO_HAVE_OTHER: 'その他のおすすめ',
    INCLUDED: '含まれるもの'
  },
  week: {
    SUNDAY: '日',
    MONDAY: '月',
    TUESDAY: '火',
    WEDNESDAY: '水',
    THURSDAY: '木',
    FRIDAY: '金',
    SATURDAY: '土'
  },
  category: {
    RAILWAY_TICKET: '鉄道乗車券',
    AIRPORT_TRANSPORTATION: '空港アクセス',
    SHINKANSEN: '新幹線',
    EXPRESS_BUS: '高速バス',
    BUS_TOUR: 'バスツアー',
    CRUISES: 'フェリー・クルーズ',
    RENTAL_CAR: 'レンタカー',
    CHARTER: 'チャーター',
    ATTRACTION: '施設（入場券）',
    THEME_PARK: 'テーマパーク',
    MUSEUM_GALLERY: 'ミュージアム・ギャラリー',
    AQUARIUM_ZOO: '水族館・動物園',
    TEMPLES_SHINES: '寺社仏閣',
    TOWER_BUILDING: 'タワー・展望台',
    JAPANESE_RESTAURANT: '和食レストラン',
    WESTERN_RESTAURANT: '洋食レストラン',
    CHINESE_RESTAURANT: '中華レストラン',
    OTHER_RESTAURANT: 'レストラン（その他）',
    DESSERT_BEVERAGE: 'デザート・ドリンク',
    SIGHTSEEING_TOUR: '観光ツアー',
    MARINE_SPORTS: 'マリンスポーツ',
    OUTDOOR_ACTIVITIES: 'アウトドアアクティビティ',
    ONSEN: '温泉',
    SPA_MASSAGE: 'スパ・マッサージ',
    JAPANESE_CULTURAL_EXPERIENCE: '日本文化体験',
    JAPANESE_CRAFT_EXPERIENCE: '日本工芸体験',
    JAPANESE_COOKING_EXPERIENCE: '和食作り体験',
    OTHER_EXPERIENCE: 'その他の体験',
    HOTEL: 'ホテル',
    RYOKAN: '旅館',
    VACATION_RENTAL: '民泊',
    BUNDLE: 'セット',
    PASS: 'パス',
    OTHERS: 'その他'
  },
  bookingField: {
    PARTICIPANT_EMAIL_ADDRESS: '参加者メールアドレス',
    HOTEL_NAME: '宿泊先名',
    HOTEL_ADDRESS: '宿泊先住所',
    HOTEL_RESERVATION_LAST_NAME: '宿泊代表者氏名（姓）',
    HOTEL_RESERVATION_FIRST_NAME: '宿泊代表者氏名（名）',
    HOTEL_TEL: '宿泊先電話番号',
    ARRIVAL_DATE: '現地到着日',
    ARRIVAL_TIME: '到着時刻',
    ARRIVAL_FLIGHT_NUMBER: 'フライト到着便名',
    DEPARTURE_DATE: '出発日',
    DEPARTURE_TIME: '出発時刻',
    DEPARTURE_FLIGHT_NUMBER: 'フライト出発便名',
    DESTINATION_EMAIL: '現地連絡先（メールアドレス）',
    DESTINATION_TEL: '現地連絡先（電話番号）',
    PARTICIPANT_RESIDENT_REGION: '参加者居住国・地域',
    PARTICIPANT_LANGUAGE: '参加者言語'
  },
  extendedBookingField: {
    PASSENGERS_QUANTITY: '乗車人数',
    PREFERRED_START_TIME: '希望開始時間',
    PREFERRED_PICK_UP_LOCATION: 'お迎え・乗車場所',
    PREFERRED_DROP_OFF_LOCATION: 'お送り・降車場所',
    PARTICIPANTS_QUANTITY: '参加人数',
    LUGGAGE_QUANTITY: 'スーツケースの数（合計）',
    CHILDREN_WITHOUT_SEAT_QUANTITY: '座席を必要としない幼児',
    CHILD_SEAT_QUANTITY_PAID: 'チャイルドシートのご利用 ※現地払い',
    CHILD_SEAT_QUANTITY_FREE: 'チャイルドシートのご利用 ※無料',
    PREFERRED_GUIDE_LANGUAGE_CODE: 'ご希望のガイド言語',
    PREFERRED_DRIVER_LANGUAGE_CODE: 'ご希望のドライバー言語'
  },
  extendedParticipantField: {
    PASSPORT_FIRST_NAME: '参加者姓（パスポート記載のローマ字姓）',
    PASSPORT_LAST_NAME: '参加者名（パスポート記載のローマ字名）',
    PASSPORT_NATIONALITY: '国籍',
    PASSPORT_NUMBER: 'パスポート番号',
    DATE_OF_BIRTH: '生年月日',
    AGE: '年齢',
    GENDER: '性別',
    EYESIGHT: '視力',
    WEIGHT_KG: '体重（kg）',
    HEIGHT_CM: '身長（cm）',
    SHOE_SIZE_CM: '靴のサイズ（cm）',
    CLOTHES_SIZE: '服のサイズ',
    VEGETARIAN_MEALS: 'ベジタリアンフード希望',
    DIVING_EXPERIENCE_TIMES: 'ダイビング経験本数',
    RENTAL_EQUIPMENTS_FREE: '器材レンタル希望 ※無料',
    RENTAL_EQUIPMENTS_PAY_LOCALLY: '器材レンタル希望 ※現地払い'
  },
  templateStatus: {
    DRAFT: '下書き',
    REVIEWING: '公開申請中',
    APPROVED: '公開確認済',
    PUBLISHED: '公開済'
  },
  universalType: {
    TRUE: '各プラン共通',
    FALSE: '各プラン異なる'
  },
  datePeriod: {
    DAY: '日間',
    MONTH: 'ヶ月間',
    tilde: '〜'
  },
  voucherExpirationType: {
    FROM_PURCHASE_DATE: '購入日から',
    FROM_ACTIVITY_DATE: '参加日から',
    FIXED_DATE: '絶対固定日',
    RELATIVE_FIXED_DATE: '相対固定日'
  },
  operation: {
    CREATE: '商品作成しました',
    UPDATE: '編集',
    DELETE: '削除',
    SUBMIT_FOR_REVIEW: '公開申請しました',
    REJECT: '差し戻し',
    APPROVE: '公開申請を承認しました',
    PUBLISH: '公開しました'
  },
  jobState: {
    ENABLED: '成功',
    PAUSED: '停止',
    DISABLED: '無効',
    UPDATE_FAILED: '更新失敗',
    ERROR: 'エラー'
  },
  salesStatus: {
    ON: 'Normal',
    OFF: 'Archived'
  }
};
