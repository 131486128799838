import { useEffect } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from '@link/gds-supplier/stores';

const PrivateRoute = () => {
  const { auth, backend, timeZone } = useStore();
  const location = useLocation();
  useEffect(() => {
    if (auth.isAdmin) {
      backend.listBackends();
    }
  }, [auth.isAdmin, backend]);

  useEffect(() => {
    timeZone.getTimeZones();
  }, [timeZone]);

  if (auth.loggedIn) {
    return <Outlet />;
  } else {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
};
const ObserverPrivateRoute = observer(PrivateRoute);
export default ObserverPrivateRoute;
