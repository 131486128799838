import { action, computed, observable } from 'mobx';
import { sleep } from '@link/gds-share/utils';
import { User, Claims } from '@link/gds-supplier/vendors/firebase';

const storage = localStorage;
const ACCESS_TOKEN = 'accessToken';
const ADMIN = 'admin';

const MAX_REFRESH_COUNT = 10;

const TIMEOUT = 300;

export class Auth {
  @observable accessor user: User | null = null;
  @observable accessor claims: Claims | null = null;
  @observable accessor accessToken = storage.getItem(ACCESS_TOKEN) || '';

  @action
  setUser(user: User) {
    this.user = user;
    this.getClaims(user);
  }

  @action
  async getClaims(user: User) {
    const tokenResult = await user.getIdTokenResult();
    if (tokenResult) {
      this.setClaims(tokenResult.claims as Claims);
    }
  }

  @action
  setClaims(claims: Claims) {
    this.claims = claims;
  }

  @action
  clearUser() {
    this.user = null;
    try {
      storage.removeItem(ACCESS_TOKEN);
    } finally {
      this.accessToken = '';
    }
  }

  @action
  setToken(token: string) {
    try {
      storage.setItem(ACCESS_TOKEN, token);
    } finally {
      this.accessToken = token;
    }
  }

  @action
  async getToken() {
    if (this.user) {
      const token = await this.user.getIdToken();
      this.setToken(token);
      return token;
    } else {
      return '';
    }
  }

  @action
  async getRefreshedToken() {
    let count = 0;
    const requestToken: () => Promise<string> = async () => {
      const token = await this.getToken();
      if (token) {
        return token;
      } else {
        if (count < MAX_REFRESH_COUNT) {
          count++;
          await sleep(TIMEOUT);
          return await requestToken();
        } else {
          return '';
        }
      }
    };
    return await requestToken();
  }

  @computed
  get loggedIn(): boolean {
    return !!this.user || !!this.accessToken;
  }

  @computed
  get isAdmin(): boolean {
    return this.claims?.supplier === ADMIN;
  }

  @computed
  get isSupplier(): boolean {
    return !!this.claims?.supplier && !this.isAdmin;
  }

  @computed
  get isSupplierAdmin(): boolean {
    return this.isSupplier && this.claims?.role === ADMIN;
  }

  @computed
  get isClaimsLoaded(): boolean {
    return Object.keys(this.claims || {}).length > 0;
  }

  @computed
  get email(): string {
    return this.user?.email || '';
  }

  @computed
  get isPasswordAuth(): boolean {
    const userInfo = this.user?.providerData[0];
    return userInfo?.providerId === 'password';
  }
}

export default new Auth();
