// Generated i18n file
export default {
  component: {
    form: {
      id: '预约信息ID',
      title: '预约信息标题',
      info: '预约信息说明',
      setting: '预约信息设置方式',
      deadline: '截止类型',
      answerType: '回答形式',
      selectItems: '选择项目',
      selectPrompt: '请选擇'
    },
    rule: {
      type: '预约类型',
      possibleInterval: '可预约期间',
      interval: '预约截止时间',
      daysBefore: '天前',
      timeBefore: '直到时间'
    },
    cancel: {
      notCancellable: '预约后不接受取消或更改。',
      freeCancellable: '换票前可免费取消，换票后不予退款。',
      flex: '设置高级取消政策',
      preview: '预览',
      cancelPreview: '取消政策预览',
      info: '自定义说明文',
      timeSetting: '取消日期设置',
      day: '第{D}天',
      timeFormat: '时间{HH:MM}',
      time: '时间{T}',
      feeSetting: '取消费用设置',
      feeSettingPrompt: '请选取消费设置',
      cancelPolicyPrompt: '请选取消政策',
      priceReferenceSale: '价格基准：销售价格',
      priceReference: '价格基准：',
      fromClient: '旅行者来源（%）',
      fromAgent: '旅行代理商来源（%）',
      toSupplier: '供应商来源（%）',
      paymentCancelFee: '取消费用支付',
      fromClientToAgent: '旅行者到代理商',
      fromAgentToLinktivity: '代理商到LINKTIVITY',
      fromLinktivityToSupplier: 'LINKTIVITY到供应商'
    },
    checklist: {
      notice: '注意事项 {{index}}',
      selectNoticePrompt: '请选参加前及参加中的注意事项',
      uploadPDF: '上传PDF',
      uploadImage: '上传图片',
      googleMapSetting: 'Google Map设置'
    },
    faq: {
      question: '问题',
      answer: '回答',
      faqTitle: '项目 {{index}}'
    },
    itinerary: {
      title: '标题',
      detailedSchedule: '详细行程'
    },
    startTime: {
      id: 'ID',
      name: '开始时间',
      title: '标题',
      poolId: '库存 ID'
    },
    location: {
      title: '{{label}} 名称',
      address: '地址',
      placeId: '地点 ID',
      latlong: '纬度经度',
      select: '选择 {{label}}',
      errMsg: '搜索目标无法找到。请检查输入内容或直接从地图选择。'
    },
    price: {
      info: '价格 {{index}}',
      reservableInterval: '可预约期间',
      startTime: '开始时间',
      endTime: '结束时间',
      unitType: '单位种类',
      unitPrice: '单位价格',
      soldPrice: '销售价格',
      agentPrice: '批发价格',
      comission: '佣金',
      price: '价格',
      accuracy: '精确度',
      rate: '汇率',
      comissionRate: '显示佣金率',
      comissionRateTips: '该汇率将显示在明细或报表中。',
      auto: '汇率精度设置',
      applicableSetting: '适用日期设置',
      applicableInterval: '适用期间',
      applicableDates: '适用星期',
      otherApplicableDates: '其他适用日',
      unitRange: '单位范围',
      standardPrice: '标准价格',
      numberIncluded: '定员包含',
      soloPurchase: '单独购买'
    },
    voucher: {
      incrementDate: '年度增分基准日'
    },
    backendBundle: {
      backendOption: 'Backend',
      bundleOption: '套餐',
      backendId: 'Backend库存ID',
      bundleChildAllotmentPoolId: '相关子商品库存ID',
      parentStartTimeId: '开始时间库存ID',
      refTime: '参考时间',
      childStartTimeId: '子商品开始时间库存ID',
      addPrompt: '添加相关子商品ID',
      label: '选择Backend',
      bundleStartTimeSetting: '设置开始时间ID',
      segmentTime: '开始时间ID',
      childSegmentTimeId: '子商品开始时间ID',
      backendAllotment: 'Backend库存设置',
      textPlaceholder: '请选ID',
      bundleAddPrompt: '为Bundle添加库存设置',
      bundleGroupHeaderText: '相关子商品ID'
    },
    backendForm: {
      cloudSchedulerJob: '同步ID',
      state: '状态',
      status: '状态',
      lastExecution: '上次更新',
      nextExecution: '下一次更新',
      selectSupplier: '选择供应商',
      selectSupplierPlaceholder: '请选择供应商',
      name: '管理名称',
      backendPoolId: 'Backend库存管理ID',
      backend: 'Backend信息',
      selectBackendPlaceholder: '请选连接的Backend',
      supplierPoolId: '供应商池ID',
      scheduleTimezone: '更新时间区',
      schedule: '更新计划',
      scheduleNote: '请参考此处的说明方法'
    },
    allotmentForm: {
      supplierId: '供应商名称',
      selectSupplierPlaceholder: '请选择供应商',
      id: '库存ID',
      name: '库存管理名称',
      freesale: '自由销售',
      copyCalendarEdits: '复制日历编辑'
    },
    dateSetting: {
      titlePlaceholder: '请填写库存管理名称',
      name: '基本库存设置',
      interval: '适用期间（星期几）',
      exactDates: '个别适用日',
      commonAllotment: '库存设置',
      add: '添加基本库存',
      common: '共用',
      freesale: '自由销售',
      createTitle: '添加库存设置',
      modal: {
        interval: '适用期间',
        dates: '适用星期',
        otaAllotments: '代理商库存设置'
      }
    },
    exception: {
      name: '例外开始时间设置',
      default: '基本库存',
      allowedDefault: '可用',
      disallowedDefault: '不可用',
      defaultApplicable: '（基本库存设置适用：{{applicable}}）',
      applicable: '适用',
      notApplicable: '不适用'
    },
    segmentId: {
      name: '开始时间ID',
      addPrompt: '添加开始时间ID',
      refTime: '参考时间',
      startTimeIdRequired: '请填写开始时间ID',
      startTimeRequired: '请填写开始时间'
    },
    otaGroup: {
      name: '代理商库存组设置',
      groupName: '代理商库存组名称',
      addPrompt: '添加代理商库存设置',
      addOta: '添加代理商',
      deleteOta: '删除代理商'
    },
    calendar: {
      groupRule: '显示库存',
      week: '周',
      month: '月',
      showMore: '详情',
      amountReserved: '实际预定数量',
      amountLimit: '最大预约数量',
      amountBackend: '供应商库存'
    },
    editAllotment: {
      type: '类型',
      amountReserved: '已预定',
      amountEdited: '库存配额',
      amountRemains: '剩余库存',
      shared: '共享库存'
    }
  },
  common: {
    account: '账号',
    signOut: '登出',
    no: '否',
    yes: '是',
    add: '添加',
    or: '或者',
    edit: '编辑',
    register: '注册',
    save: '保存',
    on: '开启',
    off: '关闭',
    cancel: '取消',
    confirm: '确认',
    colon: '：'
  },
  lang: {
    en: '英语',
    ja: '日语',
    zh: '简体中文',
    zt: '繁体中文',
    ko: '韩文'
  },
  layouts: {
    sidebar: {
      dashboard: '仪表板',
      activitiesManagement: '产品管理',
      activities: '产品列表',
      settings: '设置',
      info: '基本信息',
      detail: '详细信息',
      agent: '价格组设置',
      i18n: '多语言设置',
      planInfo: '计划 - {{title}}',
      booking: '预约信息设置',
      price: '单位/价格设置',
      addPlan: '添加计划',
      planName: '计划名称',
      duplicatePlan: '复制计划',
      adminSetting: '管理设置',
      adminPlanInfo: '计划 - {{title}} 设置',
      adminActivity: '产品设置',
      adminPlan: '计划设置',
      inventory: '库存设置',
      backendInventory: 'Backend库存设置',
      editMemo: '编辑备注',
      editLog: '编辑历史',
      allotmentSetting: '营业日与库存设置',
      allotmentSegmentId: '添加开始时间ID',
      allotmentException: '例外开始时间设置',
      allotmentCalendar: '编辑日历',
      allotmentOtaGroup: '代理商库存组设置',
      allotmentBackend: 'Backend设置',
      backendCalendar: '日历'
    },
    relatedLinks: {
      links: '相关链接'
    }
  },
  "firebase['auth/wrong-password']": '登录名或密码错误',
  "firebase['auth/user-not-found']": '登录名或密码错误',
  "firebase['auth/email-already-in-use']": '邮箱地址已被注册',
  "firebase['auth/too-many-requests']":
    '登录尝试次数过多。请稍后再试或重置密码。',
  validation: {
    required: '请填写{{name}}',
    requiredSelect: '请选择',
    invalid: '请填写有效的{{name}}',
    password: '请设置包含字母和数字的至少8个字符密码'
  },
  views: {
    common: {
      activityId: '产品ID',
      yes: '有',
      no: '没有',
      edit: '编辑',
      close: '关闭',
      editConfirm: '确认编辑',
      successCreatedMsg: '成功添加{{name}}',
      errorCreatedMsg: '添加{{name}}失败',
      successDeletedMsg: '成功删除{{name}}',
      errorDeletedMsg: '删除{{name}}失败',
      successUpdatedMsg: '成功更新{{name}}',
      errorUpdatedMsg: '更新{{name}}失败'
    },
    dashboard: '仪表板',
    activities: {
      name: '产品名称',
      title: '产品标题',
      edit: '编辑',
      confirmed: '商品确认成功',
      status: '状态',
      list: '产品列表',
      addActivity: '注册商品',
      addNewActivity: '新商品注册',
      add: '添加',
      selectSupplier: '选择供应商',
      inputSupplierTitle: '请填写商品名称',
      copyTitle: '商品复制',
      selectSupplierLabel: '选择供应商',
      copy: '复制'
    },
    allotmentSettings: {
      pageTitle: '营业日与库存设置',
      title: '库存设置',
      management: '库存注册',
      poolId: '管理ID',
      supplierId: '供应商名称',
      name: '库存管理名称',
      sharedAllotment: '共享库存',
      inputAllotmentName: '请填写库存管理名称',
      leaveCurrentPageConfirm: '库存设置仍在编辑中，确定要离开此页面吗？',
      leaveCurrentPage: '离开此页面',
      continueEditing: '继续编辑',
      edit: {
        name: '库存',
        createTitle: '新增库存',
        copyTitle: '复制库存'
      }
    },
    backendSettings: {
      title: 'Backend库存设置',
      newTitle: '新建Backend库存设置',
      management: '库存注册',
      poolId: '管理ID',
      supplierId: '供应商名称',
      name: '管理名称',
      confirm: '确定',
      state: '状态',
      status: '状态',
      lastExecution: '上次更新',
      nextExecution: '下一次更新',
      sharedAllotment: '共享库存',
      jobStatus: {
        success: '成功',
        failed: '失败'
      }
    },
    activity: {
      log: {
        logHistory: '商品编辑历史',
        priceHistory: '价格组别编辑历史',
        chooseLog: '选择编辑历史',
        chooseItemLabel: '套餐 {{index}} 编辑历史',
        html: 'HTML Log',
        by: 'by'
      },
      memo: {
        title: '编辑笔记',
        tips: '编辑笔记仅供编辑商品时的备注目的，不会显示在代理商或直销网站页面。',
        successUpdated: '已保存笔记'
      },
      tag: {
        title: '编辑标签',
        tips: '编辑标签仅供编辑商品时的记录目的，不会显示在代理商或直销网站页面。'
      },
      paymentCollector: '收款人',
      productDescription: '商品说明',
      priceSetting: '币别设置',
      autoPublic: '自动发布设置',
      autoPublicGroup: '请选择对象组别',
      imageSetting: '图像设置',
      participantNotice: '参加前・参加时注意事项（费用包含等）',
      agentGroupName: '价格组别名称',
      agentSaved: '已保存代理商',
      agentSetting: '价格组别设置',
      addAgentGroup: '添加价格组别',
      agentTips:
        '※因价格组别会与各个代理商联合，不可将同代理商添加至复数组别。',
      comment: '留言',
      publishedApplied: '已提出发布申请。',
      rejectApplied: '已驳回申请。',
      approved: '已批准。',
      published: '已发布。',
      publish: '发布',
      reject: '驳回',
      approve: '发布批准',
      submit: '发布申请',
      save: '保存',
      preview: '预览',
      applyForPublish: '商品发布申请',
      confirmReject: '驳回批准',
      confirmApprovalOfProduct: '商品发布批准',
      productContentConfirmed: '已确认商品内容。',
      confirmForPublishingProducts: '商品发布确认',
      productPublishedConfirmed: '已批准商品发布。',
      productSaved: '已保存商品',
      languageSetting: '语言设置',
      languageSelection: '选择语言',
      productTitle: '商品名称（订购凭证名称）',
      origin: '出发地',
      destination: '目的地',
      checkinPoints: '集合地点',
      checkoutPoints: '解散地点',
      pickupLocation: '上车地点',
      dropoffLocations: '下车地点',
      cancellationPolicy: '取消政策',
      faqSetting: '常见问题设置',
      chosenLanguage: '刊登语言',
      timeZone: '国家・地区（时区）',
      category: '类别',
      useQrCode: '使用电子票',
      qrcodeType: '二维码类型',
      qrcodeDisplayLocations: '二维码显示位置',
      voucherSetting: '订购凭证设置',
      voucherVersion: '订购凭证版本',
      appointmentSetting: '订购规则设置',
      appointmentNotification: '订单通知',
      useEticketItems: '电子票使用项目',
      qrcodeTemplate: '二维码模板',
      qrcodeHeader: '二维码标题',
      qrcodeFooter: '二维码页脚',
      qrcodeGenerator: '二维码生成码',
      redemptionUrlTemplate: '兑换网址模板',
      adminSettingSaved: '已保存管理设置',
      allotmentSettingSaved: '已保存套餐设置',
      planSelection: '选择套餐',
      planId: '套餐ID',
      systemCharge: '系统费用',
      productInfo: '商品资料',
      supplierId: '供应商ID',
      supplierName: '供应商名称',
      name: '商品名称',
      editMemo: '编辑笔记',
      editTag: '编辑标签',
      publishActivityConfirm: '要发布本商品吗？',
      publishActivitySuccess:
        '※商品发布成功后，被添加至价格组别内的代理商即可查阅商品页面。',
      inPublish: '发布中',
      isActivityPublished: '商品发布成功',
      isActivityPublishedFailed: '商品发布失败',
      contactUs: '请洽询至系统负责人，不便之处还请见谅。',
      leaveCurrentPage: '离开此页面',
      continueEditing: '继续编辑',
      salesStatus: '显示本商品',
      hideFromSearch: '显示于商品列表',
      salesStatusNotes:
        '※若变更商品显示状态，需重新发布才可成功变更，敬请注意。',
      hideFromSearchNotes:
        '※若关闭显示商品列表功能，本商品将无法于搜索结果或商品列表中显示，敬请注意。',
      qrCodeTips:
        '※如希望设定『二维码一人一码』或『Mogiri电子票』请联系本公司的系统负责人。'
    },
    plan: {
      saveConfirm: '已保存套餐',
      name: '套餐名称',
      save: '保存',
      planPublish: '显示刊登套餐',
      bookingRule: '订购规则设置',
      voucherExpiration: '订购凭证有效期',
      expirationVisibilitySetting: '隐藏订购凭证有效期',
      visibilitySetting: {
        hideFromVoucher: '隐藏订购凭证',
        hideFromActivityDetails: '隐藏页面（商品内容）',
        hideFromBookingDetails: '隐藏页面（订单详情）'
      },
      checkinPoint: '集合地点',
      checkoutPoint: '解散地点',
      pickupLocation: '上车地点',
      dropoffLocation: '下车地点',
      unitSetting: '单位设置',
      priceSetting: '费用设置',
      bookingAgentRequiredInfo: '代表人信息-每笔订单（必填）',
      bookingAgentRecommendedInfo: '代表人信息-每笔订单（任意）',
      bookingOtherRequiredInfo: '其他信息-每笔订单（必填）',
      bookingOtherRecommendedInfo: '其他信息-每笔订单（任意）',
      participantRequiredInfo: '使用者信息-每位参加者（必填）',
      participantRecommendedInfo: '使用者信息-每位参加者（任意）',
      customBookingInfo: '自订订购信息',
      settingUnable: '结束日期无法设置于开始日期以前',
      description: '套餐说明',
      imageSetting: '图像设置',
      availablePeriod: '发布区间',
      reservationRestrict: '订购数量限制',
      pieces: '张',
      min: '最低',
      max: '最多',
      tilde: '〜',
      checklistLabel: '参加前・参加时注意事项（费用包含等）',
      startTime: '开始时间',
      setSchedule: '行程设置',
      editConfirm: '确认编辑内容',
      leave: '离开此页面',
      continueEditing: '继续编辑',
      code: {
        id: '套餐ID',
        coupon: '隐藏套餐子商品订购凭证',
        backendId: '库存编号设置',
        backendIdLabel: '请选择库存设置',
        goToSetting: '若未设置库存，请输入<0>至</0>进行设置',
        backend: 'backend信息',
        backendLabel: '请选择希望联合的backend项目',
        productCode: 'Product Code',
        productCodeLabel: '请输入Product Code',
        unit: 'Unit设置',
        unitType: '单位类型',
        unitRange: '单位范围',
        unitCode: 'Unit Code',
        startTimeTitle: '开始时间设置',
        startTime: '开始时间',
        startTimeCode: 'Start time Code',
        codeLabel: '请输入Code'
      },
      planPublishNotes:
        '※希望变更套餐显示状态时，需重新发布才可变更成功，敬请注意。',
      leavePrompt: '套餐仍在编辑中，您确定要离开此页面吗？'
    },
    login: {
      title: 'Welcome to GDS',
      email: '电子邮箱',
      password: '密码',
      signIn: '登录',
      forgotPassword: '忘记密码',
      googleLogin: '使用Google帐户登录'
    },
    password: {
      changePassword: '变更密码',
      resetPassword: '重设密码',
      sendResetEmail: '发送重设密码电子邮件',
      oldPassword: '旧密码',
      newPassword: '新密码',
      changePasswordSuccess: '已变更密码'
    }
  }
};
