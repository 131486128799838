import { action, observable } from 'mobx';

export interface IBreadcrumbRoute {
  to?: string;
  name?: string;
}

export class Breadcrumb {
  @observable accessor routes: IBreadcrumbRoute[] = [];

  @action
  setRoutes(routes: IBreadcrumbRoute[]) {
    this.routes = routes;
  }

  @action
  clear() {
    this.routes = [];
  }
}

export default new Breadcrumb();
