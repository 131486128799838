import { action, computed, observable, toJS } from 'mobx';
import { supplierApi } from '@link/gds-supplier/services';
import { Supplier as ISupplier } from '@link/gds-supplier/services/types';

const storage = localStorage;
const SUPPLIER_ID = 'supplierId';
const SUPPLIER_LIST = 'supplierList';
export class Supplier {
  nextCursor = '';
  @observable accessor suppliers: Array<ISupplier> = JSON.parse(
    storage.getItem(SUPPLIER_LIST) || '[]'
  );
  @observable accessor currentSupplierInfo: ISupplier | null = null;
  @observable accessor currentActivitySupplier: ISupplier | null = null;
  @observable accessor currentSupplierId = storage.getItem(SUPPLIER_ID) || '';
  @observable accessor loading = false;

  @action
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @action
  async fetchSuppliers() {
    const response = await supplierApi.listSuppliers({
      body: {
        cursor: this.nextCursor
      }
    });
    const { suppliers, nextCursor = '' } = response;
    if (suppliers) {
      this.addSuppliers(suppliers);
    }
    this.setCursor(nextCursor);
    if (nextCursor === '') {
      this.setLoading(false);
      storage.setItem(SUPPLIER_LIST, JSON.stringify(this.suppliers));
    }
    return response;
  }

  @action
  async fetchAllSuppliers() {
    if (!this.loaded) {
      this.setLoading(true);
      const { nextCursor } = await this.fetchSuppliers();
      if (nextCursor) {
        await this.fetchAllSuppliers();
      }
    }
  }

  @action
  async getSupplierInfo() {
    if (!this.currentSupplierInfo) {
      const supplier = await supplierApi.getSupplier({
        body: {
          supplierId: ''
        }
      });
      this.setSupplierInfo(supplier);
      return supplier;
    }
  }

  @action
  async getActivitySupplier(supplierId: string) {
    if (this.currentActivitySupplier?.id === supplierId) {
      return toJS(this.currentActivitySupplier);
    } else {
      const supplier = await supplierApi.getSupplier({
        body: {
          supplierId
        }
      });
      this.setCurrentActivitySupplier(supplier);
      return supplier;
    }
  }

  @action
  setCurrentActivitySupplier(supplier: ISupplier) {
    this.currentActivitySupplier = supplier;
  }

  @action
  setSupplierInfo(supplier: ISupplier) {
    this.currentSupplierInfo = supplier;
  }

  @action
  addSuppliers(suppliers: Array<ISupplier>) {
    this.suppliers = this.suppliers.concat(suppliers);
  }

  @action
  clearSuppliers() {
    this.suppliers = [];
    this.nextCursor = '';
  }

  @action
  setCursor(cursor: string) {
    this.nextCursor = cursor;
  }

  @action
  setSupplierId(supplierId: string) {
    try {
      storage.setItem(SUPPLIER_ID, supplierId);
    } finally {
      this.currentSupplierId = supplierId;
    }
  }

  @computed
  get supplierName() {
    return this.currentSupplierInfo?.name || '';
  }

  @computed
  get currentSupplier() {
    const supplier = this.suppliers.find(
      supplier => supplier.id === this.currentSupplierId
    );
    return supplier;
  }

  @computed
  get supplierOptions() {
    return this.suppliers.map(supplier => ({
      label: `${supplier.id} (${supplier.name})`,
      value: supplier.id || ''
    }));
  }
  @computed
  get loaded() {
    return this.suppliers.length > 0 && this.nextCursor === '';
  }
}

export default new Supplier();
