import { action, computed, observable, toJS } from 'mobx';
import { allotmentApi } from '@link/gds-supplier/services';
import { AllotmentSetting } from '@link/gds-supplier/services/types';

export class Allotment {
  @observable accessor allotment: AllotmentSetting | null = null;

  @action
  async getAlloment(poolId: string) {
    const allotment = await allotmentApi.getAllotmentSetting({
      body: { poolId }
    });
    if (allotment) {
      this.setAlloment(allotment);
    }
  }

  @action
  async reloadAlloment() {
    if (this.poolId) {
      await this.getAlloment(this.poolId);
    }
  }

  @action
  setAlloment(allotment: AllotmentSetting) {
    this.allotment = allotment;
  }

  @action
  clearAlloment() {
    this.allotment = null;
  }

  @computed
  get loaded(): boolean {
    return !!this.allotment?.poolId;
  }

  @computed
  get name() {
    return this.allotment?.name || '';
  }

  @computed
  get poolId() {
    return this.allotment?.poolId || '';
  }
  @computed
  get supplierId() {
    return this.allotment?.supplierId || '';
  }
  @computed
  get supplierInventoryId() {
    return this.allotment?.supplierInventoryId || '';
  }
  @computed
  get bundles() {
    return toJS(this.allotment?.bundles) || [];
  }

  @computed
  get otaSettings() {
    return toJS(this.allotment?.otaSettings) || [];
  }
  @computed
  get segments() {
    return toJS(this.allotment?.segments) || [];
  }
  @computed
  get calendarEdits() {
    return toJS(this.allotment?.calendarEdits) || [];
  }
  @computed
  get segmentRules() {
    return toJS(this.allotment?.segmentRules) || [];
  }
  @computed
  get dateSettings() {
    return toJS(this.allotment?.dateSettings) || [];
  }
  @computed
  get freesale() {
    return this.allotment?.freesale;
  }
}

export default new Allotment();
