// Generated i18n file
export default {
  component: {
    form: {
      id: '予約情報ID',
      title: '予約情報タイトル',
      info: '予約情報説明',
      setting: '予約情報設定方法',
      deadline: '回答締切',
      answerType: '回答形式',
      selectItems: '選択項目',
      selectPrompt: '選択してください'
    },
    rule: {
      type: '予約タイプ',
      possibleInterval: '予約可能期間',
      interval: '予約期限',
      daysBefore: '日前',
      timeBefore: '時まで'
    },
    cancel: {
      notCancellable: 'ご予約後のキャンセル・変更は受け付けておりません。',
      freeCancellable:
        '引換前まで無手数料で取消可。引換後、払戻は一切不可になります。',
      flex: '高度なキャンセルポリシーを設定する。',
      preview: 'プレビュー',
      cancelPreview: 'キャンセルポリシープレビュー',
      info: 'カスタム説明文',
      timeSetting: 'キャンセル日時設定',
      day: '日{D}',
      timeFormat: '時間{HH:MM}',
      time: '時間{T}',
      feeSetting: 'キャンセル料の設定',
      feeSettingPrompt: 'キャンセル料設定を選んでください',
      cancelPolicyPrompt: 'キャンセルポリシーを選択してください',
      priceReferenceSale: '価格基準：販売価格',
      priceReference: '価格基準：',
      fromClient: '旅行者から (%)',
      fromAgent: '旅行代理店から (%)',
      toSupplier: '催行会社に (%)',
      paymentCancelFee: 'キャンセル料のお支払い',
      fromClientToAgent: '旅行者から代理店へ',
      fromAgentToLinktivity: '代理店からLINKTIVITYへ',
      fromLinktivityToSupplier: 'LINKTIVITYから催行会社へ'
    },
    checklist: {
      notice: '注意事項 {{index}}',
      selectNoticePrompt: '参加前・参加時の注意事項を選んでください',
      uploadPDF: 'PDFアップロード',
      uploadImage: '画像アップロード',
      googleMapSetting: 'Google Map設定'
    },
    faq: {
      question: '質問',
      answer: '回答',
      faqTitle: '項目 {{index}}'
    },
    itinerary: {
      title: 'タイトル',
      detailedSchedule: 'スケジュール詳細'
    },
    startTime: {
      id: 'ID',
      name: '開始時間',
      title: 'タイトル',
      poolId: '在庫 ID'
    },
    location: {
      title: '{{label}}名称',
      address: '住所',
      placeId: 'Place ID',
      latlong: '緯度経度',
      select: '{{label}}選択',
      errMsg:
        '検索対象が見つかりません。入力内容を再確認するか、またはマップから直接選択してください。'
    },
    price: {
      info: '価格 {{index}}',
      reservableInterval: '予約可能期間',
      startTime: '開始時間',
      endTime: '終了時間',
      unitType: 'ユニット種類',
      unitPrice: '販売単価',
      soldPrice: '販売価格',
      agentPrice: '卸価格',
      comission: 'コミッション',
      price: '料金',
      accuracy: '精度',
      rate: 'レート',
      comissionRate: 'コミッションレート（表示上）',
      comissionRateTips:
        '明細・帳票等にレートを表示する項目はこちらのレートが表示されます',
      auto: 'レート精度設定',
      applicableSetting: '適用期日設定',
      applicableInterval: '適用期間',
      applicableDates: '適用曜日',
      otherApplicableDates: 'その他適用日',
      unitRange: '単位範囲',
      standardPrice: '標準価格',
      numberIncluded: '定員に含む',
      soloPurchase: '単独購入'
    },
    voucher: {
      incrementDate: '年の増分基準日'
    },
    backendBundle: {
      backendOption: 'Backend',
      bundleOption: 'Bundle',
      backendId: 'Backend在庫ID',
      bundleChildAllotmentPoolId: '関連子商品在庫ID',
      parentStartTimeId: '開始時間在庫ID',
      refTime: '参考時間',
      childStartTimeId: '子商品開始時間在庫ID',
      addPrompt: '関連子商品ID追加',
      label: 'Backend選択',
      bundleStartTimeSetting: '開始時間ID設定',
      segmentTime: '開始時間ID',
      childSegmentTimeId: '子商品開始時間ID',
      backendAllotment: 'Backend在庫設定',
      textPlaceholder: 'IDを選択してください',
      bundleAddPrompt: 'Bundle用在庫設定追加',
      bundleGroupHeaderText: '関連子商品ID'
    },
    backendForm: {
      cloudSchedulerJob: '同期ID',
      state: '状態',
      status: 'ステータス',
      lastExecution: '前回更新',
      nextExecution: '次回更新',
      selectSupplier: 'サプライヤー選択',
      selectSupplierPlaceholder: '催行会社を選択してください',
      name: '管理名',
      backendPoolId: 'Backend在庫管理ID',
      backend: 'Backend情報',
      selectBackendPlaceholder: '連携するBackendを選択してください',
      supplierPoolId: 'Supplier Pool ID',
      scheduleTimezone: '更新タイムゾーン',
      schedule: '更新スケジュール',
      scheduleNote: '※記載方法はこちらをご参考ください'
    },
    allotmentForm: {
      supplierId: '催行会社名',
      selectSupplierPlaceholder: '催行会社を選択してください',
      id: '在庫ID',
      name: '在庫管理名',
      freesale: 'フリーセール',
      copyCalendarEdits: 'カレンダー編集コピー'
    },
    dateSetting: {
      titlePlaceholder: '在庫管理名を入力してください',
      name: '基本在庫設定',
      interval: '適用期間（曜日）',
      exactDates: '個別適用日',
      commonAllotment: '在庫設定',
      add: '基本在庫追加',
      common: 'Common',
      freesale: 'フリーセール',
      createTitle: '基本在庫設定追加',
      modal: {
        interval: '適用期間',
        dates: '適用曜日',
        otaAllotments: '代理店在庫設定'
      }
    },
    exception: {
      name: '例外開始時間設定',
      default: '基本在庫',
      allowedDefault: '利用可能',
      disallowedDefault: '利用不可',
      defaultApplicable: '(基本在庫設定適用: {{applicable}})',
      applicable: '適用する',
      notApplicable: '適用しない'
    },
    segmentId: {
      name: '開始時間ID',
      addPrompt: '開始時間ID追加',
      refTime: '参考時間',
      startTimeIdRequired: '開始時間IDを入力してください',
      startTimeRequired: '開始時間を入力してください'
    },
    otaGroup: {
      name: '代理店在庫グループ設定',
      groupName: '代理店在庫グループ名',
      addPrompt: '代理店在庫設定追加',
      addOta: '代理店追加',
      deleteOta: '代理店削除'
    },
    calendar: {
      groupRule: '在庫表示',
      week: '週',
      month: '月',
      showMore: '詳しく',
      amountReserved: '実際の予約数',
      amountLimit: '最大予約数',
      amountBackend: 'Supplier在庫'
    },
    editAllotment: {
      type: '種類',
      amountReserved: '予約済',
      amountEdited: '在庫枠',
      amountRemains: '在庫残数',
      shared: '共有在庫'
    }
  },
  common: {
    account: 'アカウント',
    signOut: 'サインアウト',
    no: 'いいえ',
    yes: 'はい',
    add: '追加',
    or: 'または',
    edit: '編集',
    register: '登録',
    save: '保存',
    on: 'オン',
    off: 'オフ',
    cancel: 'キャンセル',
    confirm: '確定',
    colon: ':'
  },
  lang: {
    en: '英語',
    ja: '日本語',
    zh: '中国語(簡体)',
    zt: '中国語(繁体)',
    ko: '韓国語'
  },
  layouts: {
    sidebar: {
      dashboard: 'ダッシュボード',
      activitiesManagement: '商品管理',
      activities: '商品一覧',
      settings: '設定',
      info: '基本情報',
      detail: '詳細情報',
      agent: '価格グループ設定',
      i18n: '多言語設定',
      planInfo: 'プラン - {{title}}',
      booking: '予約情報設定',
      price: 'ユニット/料金設定',
      addPlan: 'プラン追加',
      planName: 'プラン名',
      duplicatePlan: 'プラン複製',
      adminSetting: 'Admin設定',
      adminPlanInfo: 'プラン - {{title}} 設定',
      adminActivity: '商品設定',
      adminPlan: 'プラン設定',
      inventory: '在庫設定',
      backendInventory: 'Backend在庫設定',
      editMemo: 'メモ編集',
      editLog: '編集履歴',
      allotmentSetting: '営業日・基本在庫設定',
      allotmentSegmentId: '開始時間ID追加',
      allotmentException: '例外開始時間設定',
      allotmentCalendar: 'カレンダー編集',
      allotmentOtaGroup: '代理店在庫グループ設定',
      allotmentBackend: 'Backend設定',
      backendCalendar: 'カレンダー'
    },
    relatedLinks: {
      links: '関連リンク'
    }
  },
  "firebase['auth/wrong-password']": 'ログイン名またはパスワードが違います。',
  "firebase['auth/user-not-found']": 'ログイン名またはパスワードが違います。',
  "firebase['auth/email-already-in-use']":
    '既に登録されているメールアドレスです。',
  "firebase['auth/too-many-requests']":
    'ログイン試行の回数が上限を超えました。しばらくしてからもう一度お試しいただくか、パスワードをリセットしてください。',
  validation: {
    required: '{{name}}を入力してください',
    requiredSelect: '選択してください',
    invalid: '有効な{{name}}を入力してください',
    password: '文字と数字を組み合わせた8文字以上で設定してください'
  },
  views: {
    common: {
      activityId: '商品ID',
      yes: 'あり',
      no: 'なし',
      edit: '編集',
      close: '閉じる',
      editConfirm: '編集の確認',
      successCreatedMsg: '{{name}}追加に成功しました',
      errorCreatedMsg: '{{name}}追加に失敗しました',
      successDeletedMsg: '{{name}}削除に成功しました',
      errorDeletedMsg: '{{name}}削除に失敗しました',
      successUpdatedMsg: '{{name}}更新に成功しました',
      errorUpdatedMsg: '{{name}}更新に失敗しました'
    },
    dashboard: 'ダッシュボード',
    activities: {
      name: '商品名',
      title: '商品名',
      edit: '編集',
      confirmed: '商品設定完了、公開可能です。',
      status: 'ステータス',
      list: '商品一覧',
      addActivity: '商品登録',
      addNewActivity: '新規商品登録',
      add: '登録',
      selectSupplier: '催行会社を選択してください',
      inputSupplierTitle: '商品名を入力してください',
      copyTitle: '商品コピー',
      selectSupplierLabel: 'Supplier選択',
      copy: 'コピー'
    },
    allotmentSettings: {
      pageTitle: '営業日・基本在庫設定',
      title: '在庫設定',
      management: '在庫登録',
      poolId: '管理ID',
      supplierId: '催行会社名',
      name: '在庫管理名',
      sharedAllotment: '共有在庫',
      inputAllotmentName: '在庫管理名を入力してください',
      leaveCurrentPageConfirm:
        '在庫設定はまだ編集中です\\nこのページから移動してもよろしいですか？',
      leaveCurrentPage: 'このページを離れる',
      continueEditing: '編集を続ける',
      edit: {
        name: '在庫',
        createTitle: '新規在庫登録',
        copyTitle: '在庫コピー'
      }
    },
    backendSettings: {
      title: 'Backend在庫設定',
      newTitle: '新規Backend在庫設定',
      management: '在庫登録',
      poolId: '管理ID',
      supplierId: '催行会社名',
      name: '管理名',
      confirm: '確定',
      state: '状態',
      status: 'ステータス',
      lastExecution: '前回更新',
      nextExecution: '次回更新',
      sharedAllotment: '共有在庫',
      jobStatus: {
        success: 'Success',
        failed: 'Failed'
      }
    },
    activity: {
      log: {
        logHistory: '商品編集履歴',
        priceHistory: '価格グループ編集履歴',
        chooseLog: '履歴選択',
        chooseItemLabel: 'プラン {{index}} 編集履歴',
        html: 'HTML Log',
        by: 'by'
      },
      memo: {
        title: '編集用メモ',
        tips: '編集用メモは商品編集時の記録用のもので、代理店または直販サイト上では表示されません。',
        successUpdated: 'メモを保存しました'
      },
      tag: {
        title: '編集用タグ',
        tips: '編集用タグは商品編集時の記録用のもので、代理店または直販サイト上では表示されません。'
      },
      paymentCollector: '集金者',
      productDescription: '商品説明',
      priceSetting: '通貨設定',
      autoPublic: '自動公開設定',
      autoPublicGroup: '対象グループ選択',
      imageSetting: '画像設定',
      participantNotice: '参加前・参加時の注意事項（含まれるものなど）',
      agentGroupName: '価格グループ名',
      agentSaved: '代理店を保存しました',
      agentSetting: '価格グループ設定',
      addAgentGroup: '価格グループを追加',
      agentTips:
        '※価格グループは代理店ごとに価格設定と連携しているため、1つの旅行代理店を複数のグループに追加できません。\n価格グループ：1つの代理店に対して1種類の価格設定と連携させるため、1つの代理店を複数のグループに追加できません。',
      comment: 'コメント',
      publishedApplied: '公開申請しました。',
      rejectApplied: '差し戻しました。',
      approved: '承認しました。',
      published: '公開しました。',
      publish: '公開',
      reject: '差し戻し',
      approve: '公開承認',
      submit: '公開申請',
      save: '保存',
      preview: 'プレビュー',
      applyForPublish: '商品公開申請',
      confirmReject: '承認差し戻し',
      confirmApprovalOfProduct: '商品公開承認',
      productContentConfirmed: '商品の内容を確認しました。',
      confirmForPublishingProducts: '商品公開確認',
      productPublishedConfirmed: '商品の公開を承認しました。',
      productSaved: '商品を保存しました',
      languageSetting: '多言語設定',
      languageSelection: '言語選択',
      productTitle: '商品名（バウチャータイトル）',
      origin: '出発地',
      destination: '目的地',
      checkinPoints: '集合場所',
      checkoutPoints: '解散場所',
      pickupLocation: 'ピックアップ場所',
      dropoffLocations: 'お送り場所',
      cancellationPolicy: 'キャンセルポリシー',
      faqSetting: 'FAQ設定',
      chosenLanguage: '掲載言語',
      timeZone: '国・地域（タイムゾーン）',
      category: 'カテゴリ',
      useQrCode: 'QRコード利用',
      qrcodeType: 'QRコードタイプ',
      qrcodeDisplayLocations: 'QRコード表示箇所',
      voucherSetting: 'バウチャー設定',
      voucherVersion: 'バウチャーバージョン',
      appointmentSetting: '予約設定',
      appointmentNotification: '予約通知',
      useEticketItems: 'E-TICKET利用項目',
      qrcodeTemplate: 'QRコードテンプレート',
      qrcodeHeader: 'QRコードヘッダー',
      qrcodeFooter: 'QRコードフッター',
      qrcodeGenerator: 'QRコードジェネレーター',
      redemptionUrlTemplate: '引換URLテンプレート',
      adminSettingSaved: 'Admin設定を保存しました',
      allotmentSettingSaved: 'プラン設定を保存しました',
      planSelection: 'プラン選択',
      planId: 'プランID',
      systemCharge: 'システム料金',
      productInfo: '商品情報',
      supplierId: '催行会社ID',
      supplierName: '催行会社名',
      name: '商品名',
      editMemo: '編集用メモ',
      editTag: '編集用タグ',
      publishActivityConfirm: 'こちらの商品を公開しますか？',
      publishActivitySuccess:
        '※商品公開すると、価格設定に追加した代理店アカウントにて商品情報が閲覧可能となります。',
      inPublish: '公開中',
      isActivityPublished: '商品を公開しました',
      isActivityPublishedFailed: '商品の公開に失敗しました',
      contactUs: 'お手数をおかけしますが、担当者へご連絡ください。',
      leaveCurrentPage: 'このページを離れる',
      continueEditing: '編集を続ける',
      salesStatus: '商品表示',
      hideFromSearch: '一覧表示',
      salesStatusNotes:
        '※商品の表示状態を変更する場合、再度公開する必要がございますので、ご注意ください。',
      hideFromSearchNotes:
        '※一覧表示をオフにした場合、検索結果または商品一覧画面上には表示されませんので、ご注意ください。',
      qrCodeTips:
        '※『人数分のQRコード発行』または『モギリ利用』の設定は弊社の担当へご連絡ください。'
    },
    plan: {
      saveConfirm: 'プランを保存しました',
      name: 'プラン名',
      save: '保存',
      planPublish: 'プラン掲載表示',
      bookingRule: '予約設定',
      voucherExpiration: 'バウチャー有効期限',
      expirationVisibilitySetting: 'バウチャー有効期限を非表示にする',
      visibilitySetting: {
        hideFromVoucher: 'バウチャー上で非表示',
        hideFromActivityDetails: 'ウェブページ上で非表示（商品内容）',
        hideFromBookingDetails: 'ウェブページ上で非表示（予約詳細）'
      },
      checkinPoint: '集合場所',
      checkoutPoint: '解散場所',
      pickupLocation: 'ピックアップ場所',
      dropoffLocation: 'お送り場所',
      unitSetting: 'ユニット設定',
      priceSetting: '料金設定',
      bookingAgentRequiredInfo: '代表者情報-予約ごと（必須）',
      bookingAgentRecommendedInfo: '代表者情報-予約ごと（任意）',
      bookingOtherRequiredInfo: 'その他情報-予約ごと（必須）',
      bookingOtherRecommendedInfo: 'その他情報-予約ごと（任意）',
      participantRequiredInfo: '利用者情報-参加者ごと（必須）',
      participantRecommendedInfo: '利用者情報-参加者ごと（任意）',
      customBookingInfo: 'カスタマイズ予約情報',
      settingUnable: '終了日を開始日の前に設定することはできません',
      description: 'プラン説明',
      imageSetting: '画像設定',
      availablePeriod: '公開期間',
      reservationRestrict: '予約数量制限',
      pieces: '枚',
      min: '最小',
      max: '最大',
      tilde: '〜',
      checklistLabel: '参加前・参加時の注意事項（含まれるものなど）',
      startTime: '開始時間',
      setSchedule: 'スケジュール設定',
      editConfirm: '編集の確認',
      leave: 'このページを離れる',
      continueEditing: '編集を続ける',
      code: {
        id: 'プランID',
        coupon: 'Bundle子商品のバウチャーを非表示にする',
        backendId: '在庫設定ID',
        backendIdLabel: '在庫設定を選択してください',
        goToSetting: '未設定の場合は<0>こちら</0>から設定してください',
        backend: 'Backend情報',
        backendLabel: '連携するBackendを選択してください',
        productCode: 'Product Code',
        productCodeLabel: 'Product Codeを記入してください',
        unit: 'Unit設定',
        unitType: 'ユニット種類',
        unitRange: '単位範囲',
        unitCode: 'Unit Code',
        startTimeTitle: '開始時間設定',
        startTime: '開始時間',
        startTimeCode: 'Start time Code',
        codeLabel: 'Codeを記入してください'
      },
      planPublishNotes:
        '※プランの表示状態を変更する場合、商品を再公開する必要がございますので、ご注意ください。',
      leavePrompt:
        'プランはまだ編集中です。このページから移動してもよろしいですか？'
    },
    login: {
      title: 'Welcome to GDS',
      email: 'メールアドレス',
      password: 'パスワード',
      signIn: 'ログイン',
      forgotPassword: 'パスワードをお忘れの場合',
      googleLogin: 'Googleでログイン'
    },
    password: {
      changePassword: 'パスワード変更',
      resetPassword: 'パスワード再設定',
      sendResetEmail: 'パスワードリセットメールを送信する',
      oldPassword: '以前のパスワード',
      newPassword: '新しいパスワード',
      changePasswordSuccess: 'パスワードを変更しました。'
    }
  }
};
