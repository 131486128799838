// Generated i18n file
export default {
  unit: {
    AGE_ADULT_AND_CHILD: '성인 및 어린이 공통({n}-{m})세',
    AGE_ADULT: '성인({n}-{m})세',
    AGE_YOUTH: '청소년({n}-{m})세',
    AGE_CHILD: '어린이({n}-{m})세',
    AGE_SENIOR: '시니어({n}-{m})세',
    AGE_INFANT: '유아({n}-{m})세',
    AGE_STUDENT: '학생({n}-{m})세',
    AGE_HIGH_SCHOOL: '고등학생({n}-{m})세',
    AGE_INTERMEDIATE_SCHOOL: '중학생({n}-{m})세',
    AGE_ELEMENTALY_SCHOOL: '초등학생({n}-{m})세',
    AGE_DISABLED_ADULT: '장애인-성인({n}-{m})세',
    AGE_DISABLED_YOUTH: '장애인-청소년({n}-{m})세',
    AGE_DISABLED_CHILD: '장애인-어린이({n}-{m})세',
    AGE_SUPPORTER_ADULT: '간병인(성인)',
    AGE_SUPPORTER_CHILD: '간병인(어린이)',
    MINUTES_VEHICLES: '{n} 분',
    HOURS_VEHICLES: '{n} 시간',
    DAYS_VEHICLES: '{n} 일',
    WEEKS_VEHICLES: '{n} 주',
    NIGHTS_VEHICLES: '{n} 박',
    CHARTER_VAR: '1대({n}-{m})명',
    GROUP_VAR: '1그룹({n}-{m})명',
    ROOM_VAR: '1실({n}-{m})명',
    BOAT_VAR: '1척({n}-{m})명',
    PET: '애완동물',
    PET_CAT: '고양이',
    PET_DOG: '개',
    PET_DOG_S: '소형 개',
    PET_DOG_M: '중형 개',
    PET_DOG_L: '대형 개',
    PET_DOG_XL: '초대형 개',
    PET_OTHER: '기타 애완동물'
  },
  collectingType: {
    PER_PARTICIPANT: '참가자별',
    PER_BOOKING: '예약별'
  },
  collectingTiming: {
    ON_BOOKING: '예약 시 필요',
    BEFORE_ACTIVITY: '참가전 필요',
    OPTIONAL: '선택 사항'
  },
  formType: {
    DATE_ONLY_PAST: '과거일자만',
    DATE_ONLY_FUTURE: '미래일자만',
    TIME: '시간',
    YES_NO: '예/아니오',
    DROPDOWN: '드롭다운 선택',
    RADIO: '라디오 버튼',
    CHECK: '체크박스',
    TEXT: '텍스트',
    TEXT_ALPHANUMERIC: '텍스트（영숫자）',
    TEXT_PHONE: '전화번호',
    TEXTAREA: '텍스트 영역'
  },
  formatType: {
    BEFORE_ACTIVITY_FORWARD_FIXED_CLOCK:
      '이용/참가 {D}일 전, 현지시간 {HH:MM} 이후 취소 시, 예약 총액의 {N} 취소 수수료가 부과됩니다.',
    BEFORE_ACTIVITY_FORWARD_DURATION:
      '이용/참가 {T}시간 내 취소 시, 예약 총액의 {N} 취소 수수료가 부과됩니다.',
    AFTER_BOOKING_FORWARD_DURATION:
      '예약 확정 후 {T}시간 이내 취소 시, 예약 총액의 {N} 취소 수수료가 부과됩니다.',
    AFTER_BOOKING_FORWARD_FIXED_CLOCK:
      '예약 확정일 0일을 기준으로 {D}일 후, 현지시간 {HH:MM} 이후 취소 시, 예약 총액의 {N} 취소 수수료가 부과됩니다.',
    BEFORE_ACTIVITY_BACKWARD_FIXED_CLOCK:
      '이용/참가 전 {D}일, 현지시간 {HH:MM}까지 취소 시, 예약 총액의 {N} 취소 수수료가 부과됩니다.',
    BEFORE_ACTIVITY_BACKWARD_DURATION:
      '이용/참가 {T}시간 전 취소 시, 예약 총액의 {N} 취소 수수료가 부과됩니다.',
    AFTER_BOOKING_BACKWARD_DURATION:
      '예약 확정 후 {T}시간 전까지 취소 시, 예약 총액의 {N} 취소 수수료가 부과됩니다.',
    AFTER_BOOKING_BACKWARD_FIXED_CLOCK:
      '예약 확정일 0일을 기준으로 {D}일 후, 현지시간 {HH:MM}까지 취소 시, 예약 총액의 {N} 취소 수수료가 부과됩니다.'
  },
  confirmationType: {
    FREE_SALE: '참가일 지정 (자유 판매)',
    FREE_SALE_OPEN_DATE: '참가일 지정 불필요 (자유 판매)',
    INVENTORY: '참가일 지정 (실시간 재고)',
    REQUEST: '참가일 지정 필요 (요청)'
  },
  voucherType: {
    NONE: '필요 없음',
    COLLECTIVE: '예약별 QR 코드',
    INDIVIDUAL: '인원별 QR 코드'
  },
  voucherVersion: {
    VOUCHER_VERSION_V1: 'V1',
    VOUCHER_VERSION_V2: 'V2'
  },
  qrcodeType: {
    QRCODE: 'QR코드',
    TEXT: '텍스트',
    URL: 'URL',
    BARCODE_128: '바코드_128',
    BARCODE_93: '바코드_93',
    BARCODE_39: '바코드_39',
    BARCODE_25: '바코드_25'
  },
  qrcodeDisplayLocation: {
    QR_CODE_DISPLAY_LOCATION_VOUCHER: '바우처',
    QR_CODE_DISPLAY_LOCATION_ETICKET_PORTAL_SITE: '전자 티켓 표시 사이트'
  },
  voucherMedium: {
    ELECTRONIC: '인쇄 불필요 (화면 표시)',
    PAPER: '인쇄 필수'
  },
  cancellationFeeType: {
    PERCENTAGE: '퍼센트',
    ADVANCED_PERCENTAGE: '고급 퍼센트 설정',
    FIXED: '고정 요금',
    NOT_CANCELLABLE: '취소 불가'
  },
  datePeriodUnit: {
    DAY: '일 계산',
    MONTH: '월 계산'
  },
  basePrice: {
    GROSS: '판매 가격',
    NET: '도매 가격',
    SUPPLIER_NET: '공급자 도매 가격'
  },
  checklistType: {
    RESTRICTION: '참가 제한',
    REQUIRED_ITEM_TO_BRING_AND_ATTIRE: '참여 시 필요한 준비물(복장 및 소지품)',
    REQUIRED_OTHER: '참여 시 필수적인 기타 사항',
    REQUIRED_ADDITIONAL_NOTE: '비고 및 기타',
    NICE_TO_HAVE_ITEM_TO_BRING_AND_ATTIRE: '추천 준비물(복장 및 소지품)',
    NICE_TO_HAVE_OTHER: '기타 추천 사항',
    INCLUDED: '포함된 항목'
  },
  week: {
    SUNDAY: '일요일',
    MONDAY: '월요일',
    TUESDAY: '화요일',
    WEDNESDAY: '수요일',
    THURSDAY: '목요일',
    FRIDAY: '금요일',
    SATURDAY: '토요일'
  },
  category: {
    RAILWAY_TICKET: '철도 승차권',
    AIRPORT_TRANSPORTATION: '공항 교통',
    SHINKANSEN: '신칸센',
    EXPRESS_BUS: '고속버스',
    BUS_TOUR: '버스 투어',
    CRUISES: '페리 및 크루즈',
    RENTAL_CAR: '렌터카',
    CHARTER: '전세',
    ATTRACTION: '시설(입장권)',
    THEME_PARK: '테마파크',
    MUSEUM_GALLERY: '박물관 및 갤러리',
    AQUARIUM_ZOO: '수족관 및 동물원',
    TEMPLES_SHINES: '사원 및 신사',
    TOWER_BUILDING: '타워 및 전망대',
    JAPANESE_RESTAURANT: '일본식 레스토랑',
    WESTERN_RESTAURANT: '양식 레스토랑',
    CHINESE_RESTAURANT: '중식 레스토랑',
    OTHER_RESTAURANT: '기타 레스토랑',
    DESSERT_BEVERAGE: '디저트 및 음료',
    SIGHTSEEING_TOUR: '관광 투어',
    MARINE_SPORTS: '해양 스포츠',
    OUTDOOR_ACTIVITIES: '야외 활동',
    ONSEN: '온천',
    SPA_MASSAGE: '스파 및 마사지',
    JAPANESE_CULTURAL_EXPERIENCE: '일본 문화 체험',
    JAPANESE_CRAFT_EXPERIENCE: '일본 공예 체험',
    JAPANESE_COOKING_EXPERIENCE: '일본 요리 만들기 체험',
    OTHER_EXPERIENCE: '기타 체험',
    HOTEL: '호텔',
    RYOKAN: '료칸',
    VACATION_RENTAL: '민박',
    BUNDLE: '세트',
    PASS: '패스',
    OTHERS: '기타'
  },
  bookingField: {
    PARTICIPANT_EMAIL_ADDRESS: '참가자 이메일 주소',
    HOTEL_NAME: '숙박시설명',
    HOTEL_ADDRESS: '숙박시설 주소',
    HOTEL_RESERVATION_LAST_NAME: '숙박 예약 대표자 성',
    HOTEL_RESERVATION_FIRST_NAME: '숙박 예약 대표자 이름',
    HOTEL_TEL: '숙박시설 전화번호',
    ARRIVAL_DATE: '현지 도착일',
    ARRIVAL_TIME: '도착 시간',
    ARRIVAL_FLIGHT_NUMBER: '도착 항공편 번호',
    DEPARTURE_DATE: '출발일',
    DEPARTURE_TIME: '출발 시간',
    DEPARTURE_FLIGHT_NUMBER: '출발 항공편 번호',
    DESTINATION_EMAIL: '현지 연락처 (이메일)',
    DESTINATION_TEL: '현지 연락처 (전화번호)',
    PARTICIPANT_RESIDENT_REGION: '참가자 거주 국가/지역',
    PARTICIPANT_LANGUAGE: '참가자 언어'
  },
  extendedBookingField: {
    PASSENGERS_QUANTITY: '승객 수',
    PREFERRED_START_TIME: '희망 시작 시간',
    PREFERRED_PICK_UP_LOCATION: '희망 픽업 장소',
    PREFERRED_DROP_OFF_LOCATION: '희망 하차 장소',
    PARTICIPANTS_QUANTITY: '참가자 수',
    LUGGAGE_QUANTITY: '수하물 수량 (총합)',
    CHILDREN_WITHOUT_SEAT_QUANTITY: '좌석이 필요없는 어린이 수',
    CHILD_SEAT_QUANTITY_PAID: '어린이 좌석 수 (현지 결제)',
    CHILD_SEAT_QUANTITY_FREE: '어린이 좌석 수 (무료)',
    PREFERRED_GUIDE_LANGUAGE_CODE: '희망 가이드 언어',
    PREFERRED_DRIVER_LANGUAGE_CODE: '희망 운전기사 언어'
  },
  extendedParticipantField: {
    PASSPORT_FIRST_NAME: '참가자 이름 (여권상 로마자 이름)',
    PASSPORT_LAST_NAME: '참가자 성 (여권상 로마자 성)',
    PASSPORT_NATIONALITY: '국적',
    PASSPORT_NUMBER: '여권 번호',
    DATE_OF_BIRTH: '생년월일',
    AGE: '나이',
    GENDER: '성별',
    EYESIGHT: '시력',
    WEIGHT_KG: '체중 (kg)',
    HEIGHT_CM: '신장 (cm)',
    SHOE_SIZE_CM: '신발 사이즈 (cm)',
    CLOTHES_SIZE: '옷 사이즈',
    VEGETARIAN_MEALS: '채식주의자 음식 선호',
    DIVING_EXPERIENCE_TIMES: '다이빙 경험 횟수',
    RENTAL_EQUIPMENTS_FREE: '장비 대여 희망 (무료)',
    RENTAL_EQUIPMENTS_PAY_LOCALLY: '장비 대여 희망 (현지 결제)'
  },
  templateStatus: {
    DRAFT: '초안',
    REVIEWING: '공개전 확인 중',
    APPROVED: '공개 확인 완료',
    PUBLISHED: '게시됨'
  },
  universalType: {
    TRUE: '모든 플랜 공통',
    FALSE: '각 플랜 다름'
  },
  datePeriod: {
    DAY: '일 수',
    MONTH: '개월 수',
    tilde: '~'
  },
  voucherExpirationType: {
    FROM_PURCHASE_DATE: '구매일로부터',
    FROM_ACTIVITY_DATE: '참가일로부터',
    FIXED_DATE: '절대 고정 날짜',
    RELATIVE_FIXED_DATE: '상대 고정 날짜'
  },
  operation: {
    CREATE: '상품이 작성되었습니다',
    UPDATE: '수정',
    DELETE: '삭제',
    SUBMIT_FOR_REVIEW: '공개 확인을 신청 했습니다',
    REJECT: '반려',
    APPROVE: '공개 확인 신청이 승인되었습니다.',
    PUBLISH: '게시되었습니다.'
  },
  jobState: {
    ENABLED: '성공',
    PAUSED: '중지',
    DISABLED: '비활성화',
    UPDATE_FAILED: '업데이트 실패',
    ERROR: '오류'
  },
  salesStatus: {
    ON: '정상',
    OFF: '아카이브됨'
  }
};
