import { action, computed, observable, toJS } from 'mobx';
import { allotmentApi } from '@link/gds-supplier/services';
import { SupplierInventorySetting } from '@link/gds-supplier/services/types';

export class Inventory {
  @observable accessor inventory: SupplierInventorySetting | null = null;

  @action
  getInventory(poolId: string) {
    allotmentApi
      .getSupplierInventory({
        body: {
          poolId
        }
      })
      .then(res => {
        if (res) {
          this.setInventory(res);
        }
      });
  }

  @action
  async reloadInventory() {
    if (this.poolId) {
      await this.getInventory(this.poolId);
    }
  }

  @action
  setInventory(inventory: SupplierInventorySetting) {
    this.inventory = inventory;
  }

  @action
  clearInventory() {
    this.inventory = null;
  }

  @computed
  get loaded(): boolean {
    return !!this.inventory?.poolId;
  }

  @computed
  get name() {
    return this.inventory?.name || '';
  }

  @computed
  get poolId() {
    return this.inventory?.poolId || '';
  }
  @computed
  get cloudSchedulerJob() {
    return this.inventory?.cloudSchedulerJob || '';
  }
  @computed
  get state() {
    return toJS(this.inventory?.state);
  }
  @computed
  get status() {
    return toJS(this.inventory?.status) || {};
  }
  @computed
  get lastExecution() {
    return toJS(this.inventory?.lastExecution);
  }
  @computed
  get nextExecution() {
    return toJS(this.inventory?.nextExecution);
  }
  @computed
  get supplierId() {
    return this.inventory?.supplierId || '';
  }
  @computed
  get backend() {
    return this.inventory?.backend || '';
  }
  @computed
  get supplierPoolId() {
    return this.inventory?.supplierPoolId || '';
  }
  @computed
  get scheduleTimezone() {
    return this.inventory?.scheduleTimezone || '';
  }
  @computed
  get schedule() {
    return this.inventory?.schedule || '';
  }
}
export default new Inventory();
