import { action, computed, observable, toJS } from 'mobx';
import { TagType } from '@linktivity/link-ui';
import { activityApi, pricingApi } from '@link/gds-supplier/services';
import { TemplateStatus, SalesStatus } from '@link/gds-supplier/services/const';
import {
  ActivityTemplate,
  EditActivityTemplateReqPayload,
  PlanTemplate,
  PriceGroup,
  AdministrativeActivityFields
} from '@link/gds-supplier/services/types';

export class Activity {
  @observable accessor activity: ActivityTemplate | null = null;

  @action
  async getActivity(activityId: string) {
    const activity = await activityApi.getActivityTemplate({
      body: {
        templateId: activityId
      }
    });
    if (activity) {
      this.setActivity(activity);
    }
  }

  @action
  async reloadActivity() {
    if (this.activityId) {
      await this.getActivity(this.activityId);
    }
  }

  @action
  async updateActivity(payload: EditActivityTemplateReqPayload) {
    const activity = await activityApi.editActivityTemplate({
      body: {
        activityTemplateId: this.activityId,
        payload: { ...this.activity, ...payload },
        activityVersion: this.version
      }
    });
    if (activity) {
      await this.reloadActivity();
    }
  }

  @action
  updateActivityStatus(status: TemplateStatus) {
    if (this.activity) {
      this.activity.status = status;
    }
  }

  @action
  setActivityWithoutPlans(activity: ActivityTemplate) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { plans, ...updatedActivity } = activity;
    this.activity = { ...this.activity, ...updatedActivity };
  }

  @action
  updatePlan(plan: PlanTemplate) {
    const currentPlanIndex =
      this.activity?.plans?.findIndex(p => p.id === plan.id) ?? -1;
    if (currentPlanIndex >= 0 && this.activity?.plans) {
      this.activity.plans[currentPlanIndex] = plan;
    }
  }

  @action
  setActivity(activity: ActivityTemplate) {
    this.activity = activity;
  }

  @action
  clearActivity() {
    this.activity = null;
  }

  @action
  async createPlan(title: string) {
    const plan = await activityApi.createPlanTemplate({
      body: {
        activityTemplateId: this.activityId,
        title
      }
    });
    if (plan) {
      this.addPlan(plan);
    }
  }

  @action
  async duplicatePlan(title: string, sourcePlanId: string) {
    const plan = await activityApi.copyPlanTemplate({
      body: {
        activityTemplateId: this.activityId,
        sourcePlanTemplateId: sourcePlanId,
        title
      }
    });
    if (plan) {
      this.addPlan(plan);
    }
  }

  @action
  addPlan(plan: PlanTemplate) {
    if (plan) {
      this.activity?.plans?.push(plan);
    }
  }

  @action
  async addPriceGroup(name: string) {
    const activity = await pricingApi.createPriceGroup({
      body: {
        templateId: this.activityId,
        name,
        settingVersion: this.settingVersion
      }
    });
    if (activity) {
      this.setActivityWithoutPlans(activity);
    }
  }

  @action
  async updatePriceGroup(priceGroups: Array<PriceGroup>, currency: string) {
    const priceSetting = {
      ...this.priceSetting,
      priceGroups,
      currency
    };
    const activity = await pricingApi.editPriceGroup({
      body: {
        templateId: this.activityId,
        priceSetting,
        settingVersion: this.settingVersion
      }
    });
    if (activity) {
      await this.reloadActivity();
    }
  }

  @action
  async updateAdminFields(payload: AdministrativeActivityFields) {
    const activity = await activityApi.editActivityAdministrativeFields({
      body: {
        activityTemplateId: this.activityId,
        payload: { ...this.administrativeFields, ...payload },
        activityVersion: this.version
      }
    });
    if (activity) {
      await this.reloadActivity();
    }
  }

  @computed
  get loaded(): boolean {
    return !!this.activity?.id;
  }

  @computed
  get status() {
    return this.activity?.status || TemplateStatus.Draft;
  }

  @computed
  get isPublished() {
    return this.activity?.status === TemplateStatus.Published;
  }

  @computed
  get salesStatus() {
    return this.activity?.salesStatus || SalesStatus.On;
  }

  @computed
  get hideFromSearch() {
    return this.activity?.miscSettings?.hideFromSearch || false;
  }

  @computed
  get statusType() {
    switch (this.activity?.status) {
      case TemplateStatus.Draft: {
        return TagType.info;
      }
      case TemplateStatus.Reviewing: {
        return TagType.danger;
      }
      case TemplateStatus.Approved: {
        return TagType.danger;
      }
      case TemplateStatus.Published: {
        return TagType.primary;
      }
      default: {
        return TagType.info;
      }
    }
  }

  @computed
  get title() {
    return this.activity?.title || '';
  }

  @computed
  get localizedTitles() {
    return toJS(this.activity?.localizedTitles) || [];
  }

  @computed
  get description() {
    return this.activity?.description || '';
  }

  @computed
  get localizedDescriptions() {
    return toJS(this.activity?.localizedDescriptions) || [];
  }

  @computed
  get supplierId() {
    return this.activity?.supplierId || '';
  }

  @computed
  get activityId() {
    return this.activity?.id || '';
  }

  @computed
  get timeZone() {
    return this.activity?.timeZone || '';
  }

  @computed
  get administrativeFields() {
    return toJS(this.activity?.administrativeFields) || {};
  }

  @computed
  get notificationSetting() {
    return toJS(this.activity?.notificationSetting) || {};
  }

  @computed
  get plans() {
    return toJS(this.activity?.plans) || [];
  }

  @computed
  get origins() {
    return toJS(this.activity?.origins) || [];
  }

  @computed
  get destinations() {
    return toJS(this.activity?.destinations) || [];
  }

  @computed
  get checklists() {
    return toJS(this.activity?.checklists) || [];
  }

  @computed
  get universalChecklist() {
    return !!this.activity?.universalChecklist;
  }

  @computed
  get checkinPoints() {
    return toJS(this.activity?.checkinPoints) || [];
  }

  @computed
  get universalCheckinPoint() {
    return !!this.activity?.universalCheckinPoint;
  }

  @computed
  get checkoutPoints() {
    return toJS(this.activity?.checkoutPoints) || [];
  }

  @computed
  get universalCheckoutPoint() {
    return !!this.activity?.universalCheckoutPoint;
  }

  @computed
  get pickupLocations() {
    return toJS(this.activity?.pickupLocations) || [];
  }

  @computed
  get universalPickupLocation() {
    return !!this.activity?.universalPickupLocation;
  }

  @computed
  get dropoffLocations() {
    return toJS(this.activity?.dropoffLocations) || [];
  }

  @computed
  get universalDropoffLocation() {
    return !!this.activity?.universalDropoffLocation;
  }

  @computed
  get languages() {
    return toJS(this.activity?.languages) || [];
  }

  @computed
  get cancellationPolicy() {
    return toJS(this.activity?.cancellationPolicy) || {};
  }

  @computed
  get bookingRule() {
    return toJS(this.activity?.bookingRule) || {};
  }

  @computed
  get universalBookingRule() {
    return !!this.activity?.universalBookingRule;
  }

  @computed
  get images() {
    return toJS(this.activity?.images) || [];
  }

  @computed
  get category() {
    return toJS(this.activity?.category) || [];
  }

  @computed
  get ticketSetting() {
    return toJS(this.activity?.ticketSetting) || {};
  }

  @computed
  get priceSetting() {
    return toJS(this.activity?.priceSetting) || {};
  }

  @computed
  get priceGroups() {
    return toJS(this.activity?.priceSetting?.priceGroups) || [];
  }

  @computed
  get faqs() {
    return toJS(this.activity?.faqs) || [];
  }

  @computed
  get universalFaq() {
    return !!this.activity?.universalFaq;
  }

  @computed
  get version() {
    return this.activity?.activityVersion;
  }

  @computed
  get settingVersion() {
    return this.activity?.priceSetting?.settingVersion;
  }
  @computed
  get currency() {
    return this.activity?.priceSetting?.currency || '';
  }
  @computed
  get voucherPreviews() {
    return toJS(this.activity?.voucherPreviews) || [];
  }
}

export default new Activity();
