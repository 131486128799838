// Generated i18n file
export default {
  component: {
    form: {
      id: 'Reservation Info ID',
      title: 'Reservation Info Title',
      info: 'Reservation Info Description',
      setting: 'Reservation Info Settings Method',
      deadline: 'Deadline Type',
      answerType: 'Response Format',
      selectItems: 'Options',
      selectPrompt: 'Please select'
    },
    rule: {
      type: 'Reservation Type',
      possibleInterval: 'Available Reservation Period',
      interval: 'Reservation Deadline',
      daysBefore: 'Days Before',
      timeBefore: 'Until (Time)'
    },
    cancel: {
      notCancellable:
        'Cancellations or changes are not accepted after reservation .',
      freeCancellable:
        'Free cancellation before redemption. After redemption, refunds are not possible.',
      flex: 'Set an advanced cancellation policy.',
      preview: 'Preview',
      cancelPreview: 'Cancellation Policy Preview',
      info: 'Custom Description',
      timeSetting: 'Cancellation Date and Time Settings',
      day: 'Day {D}',
      timeFormat: 'Time {HH:MM}',
      time: 'Time {T}',
      feeSetting: 'Cancellation Fee Settings',
      feeSettingPrompt: 'Select the cancellation fee settings',
      cancelPolicyPrompt: 'Select a cancellation policy',
      priceReferenceSale: 'Price Basis: Sales Price',
      priceReference: 'Price Basis:',
      fromClient: 'From traveler (%)',
      fromAgent: 'From travel agent (%)',
      toSupplier: 'To supplier (%)',
      paymentCancelFee: 'Payment of cancellation fee',
      fromClientToAgent: 'From traveler to agency',
      fromAgentToLinktivity: 'From agency to LINKTIVITY',
      fromLinktivityToSupplier: 'From LINKTIVITY to supplier'
    },
    checklist: {
      notice: 'Notice {{index}}',
      selectNoticePrompt:
        'Please select pre-participation and participation notes.',
      uploadPDF: 'Upload PDF',
      uploadImage: 'Upload Image',
      googleMapSetting: 'Set Google Map'
    },
    faq: {
      question: 'Question',
      answer: 'Answer',
      faqTitle: 'Item {{index}}'
    },
    itinerary: {
      title: 'Title',
      detailedSchedule: 'Detailed Schedule'
    },
    startTime: {
      id: 'ID',
      name: 'Start Time',
      title: 'Title',
      poolId: 'Inventory ID'
    },
    location: {
      title: '{{label}} Name',
      address: 'Address',
      placeId: 'Place ID',
      latlong: 'Latitude and Longitude',
      select: 'Select {{label}}',
      errMsg:
        'No search results found. Please double-check your input or select directly from the map.'
    },
    price: {
      info: 'Price {{index}}',
      reservableInterval: 'Available Reservation Period',
      startTime: 'Start Time',
      endTime: 'End Time',
      unitType: 'Unit Type',
      unitPrice: 'Sale Unit Price',
      soldPrice: 'Sale Price',
      agentPrice: 'Net Price',
      comission: 'Commission',
      price: 'Price',
      accuracy: 'Accuracy',
      rate: 'Rate',
      comissionRate: 'Commission Rate (Displayed)',
      comissionRateTips: 'This rate will appear in statements and reports.',
      auto: 'Rate Accuracy Setting',
      applicableSetting: 'Applicable Date Settings',
      applicableInterval: 'Applicable Period',
      applicableDates: 'Applicable Days',
      otherApplicableDates: 'Other Applicable Dates',
      unitRange: 'Unit Range',
      standardPrice: 'Standard Price',
      numberIncluded: 'Included in Capacity',
      soloPurchase: 'Standalone Purchase'
    },
    voucher: {
      incrementDate: 'Annual Increment Base Date'
    },
    backendBundle: {
      backendOption: 'Backend',
      bundleOption: 'Bundle',
      backendId: 'Backend Inventory ID',
      bundleChildAllotmentPoolId: 'Related Child Product Inventory ID',
      parentStartTimeId: 'Start Time Inventory ID',
      refTime: 'Reference Time',
      childStartTimeId: 'Child Product Start Time Inventory ID',
      addPrompt: 'Add Related Child Product ID',
      label: 'Select Backend',
      bundleStartTimeSetting: 'Start Time ID Settings',
      segmentTime: 'Start Time ID',
      childSegmentTimeId: 'Child Product Start Time ID',
      backendAllotment: 'Backend Inventory Settings',
      textPlaceholder: 'Select an ID',
      bundleAddPrompt: 'Add Inventory Settings for Bundle',
      bundleGroupHeaderText: 'Related Child Product ID'
    },
    backendForm: {
      cloudSchedulerJob: 'Sync ID',
      state: 'State',
      status: 'Status',
      lastExecution: 'Last Update',
      nextExecution: 'Next Update',
      selectSupplier: 'Select a Supplier',
      selectSupplierPlaceholder: 'Select a Supplier',
      name: 'Management Name',
      backendPoolId: 'Backend Inventory Management ID',
      backend: 'Backend Information',
      selectBackendPlaceholder: 'Select Backend for Integration',
      supplierPoolId: 'Supplier Pool ID',
      scheduleTimezone: 'Update Time Zone',
      schedule: 'Update Schedule',
      scheduleNote: 'Please refer to this for instructions'
    },
    allotmentForm: {
      supplierId: 'Supplier Name',
      selectSupplierPlaceholder: 'Select a Supplier',
      id: 'Inventory ID',
      name: 'Inventory Management Name',
      freesale: 'Free Sale',
      copyCalendarEdits: 'Copy Calendar Edits'
    },
    dateSetting: {
      titlePlaceholder: 'Enter Inventory Management Name',
      name: 'Basic Inventory Settings',
      interval: 'Applicable Period (Days of the Week)',
      exactDates: 'Individual Applicable Dates',
      commonAllotment: 'Inventory Settings',
      add: 'Add Basic Inventory',
      common: 'Common',
      freesale: 'Free Sale',
      createTitle: 'Add Inventory Settings',
      modal: {
        interval: 'Applicable Period',
        dates: 'Applicable Days',
        otaAllotments: 'Agency Inventory Settings'
      }
    },
    exception: {
      name: 'Exception Start Time Settings',
      default: 'Default Inventory',
      allowedDefault: 'Available',
      disallowedDefault: 'Not Available',
      defaultApplicable: '(Basic Inventory Settings Applied: {{applicable}})',
      applicable: 'Apply',
      notApplicable: 'Do not apply'
    },
    segmentId: {
      name: 'Start Time ID',
      addPrompt: 'Add Start Time ID',
      refTime: 'Reference Time',
      startTimeIdRequired: 'Enter Start Time ID',
      startTimeRequired: 'Enter Start Time'
    },
    otaGroup: {
      name: 'Agency Inventory Group Settings',
      groupName: 'Agency Inventory Group Name',
      addPrompt: 'Add agency stock settings',
      addOta: 'Add Agency',
      deleteOta: 'Delete Agency'
    },
    calendar: {
      groupRule: 'Inventory Display',
      week: 'Week',
      month: 'Month',
      showMore: 'More',
      amountReserved: 'Actual Reserved Quantity',
      amountLimit: 'Maximum Reservation Quantity',
      amountBackend: 'Supplier Inventory'
    },
    editAllotment: {
      type: 'Type',
      amountReserved: 'Reserved',
      amountEdited: 'Inventory Slots',
      amountRemains: 'Remaining Inventory Quantity',
      shared: 'Shared Inventory'
    }
  },
  common: {
    account: 'Account',
    signOut: 'Sign Out',
    no: 'No',
    yes: 'Yes',
    add: 'Add',
    or: 'Or',
    edit: 'Edit',
    register: 'Register',
    save: 'Save',
    on: 'On',
    off: 'Off',
    cancel: 'Cancel',
    confirm: 'Confirm',
    colon: ':'
  },
  lang: {
    en: 'English',
    ja: 'Japanese',
    zh: 'Chinese (Simplified)',
    zt: 'Chinese (Traditional)',
    ko: 'Korean'
  },
  layouts: {
    sidebar: {
      dashboard: 'Dashboard',
      activitiesManagement: 'Product Management',
      activities: 'Product List',
      settings: 'Settings',
      info: 'Basic Information',
      detail: 'Detail Information',
      agent: 'Price Group Settings',
      i18n: 'Multilingual Settings',
      planInfo: 'Plan - {{title}}',
      booking: 'Booking Information Settings',
      price: 'Unit/Price Settings',
      addPlan: 'Add Plan',
      planName: 'Plan Name',
      duplicatePlan: 'Duplicate Plan',
      adminSetting: 'Admin Settings',
      adminPlanInfo: 'Plan - {{title}} Settings',
      adminActivity: 'Product Settings',
      adminPlan: 'Plan Settings',
      inventory: 'Inventory Settings',
      backendInventory: 'Backend Inventory Settings',
      editMemo: 'Edit Memos',
      editLog: 'Edit History',
      allotmentSetting: 'Business Days & Inventory Settings',
      allotmentSegmentId: 'Add Start Time ID',
      allotmentException: 'Exception Start Time Settings',
      allotmentCalendar: 'Edit Calendar',
      allotmentOtaGroup: 'OTA Group Inventory Settings',
      allotmentBackend: 'Backend Settings',
      backendCalendar: 'Calendar'
    },
    relatedLinks: {
      links: 'Related links'
    }
  },
  "firebase['auth/wrong-password']": 'The login name or password is incorrect.',
  "firebase['auth/user-not-found']": 'The login name or password is incorrect.',
  "firebase['auth/email-already-in-use']":
    'The email address is already registered.',
  "firebase['auth/too-many-requests']":
    'The number of login attempts has exceeded the limit. Please try again after a while or reset your password.',
  validation: {
    required: 'Please enter {{name}}',
    requiredSelect: 'Please select',
    invalid: 'Please enter a valid {{name}}',
    password:
      'Please create with a combination of letters and numbers, at least 8 characters long.'
  },
  views: {
    common: {
      activityId: 'Product ID',
      yes: 'Yes',
      no: 'No',
      edit: 'Edit',
      close: 'Close',
      editConfirm: 'Confirm Edit',
      successCreatedMsg: 'Successfully added {{name}}',
      errorCreatedMsg: 'Failed to add {{name}}',
      successDeletedMsg: 'Successfully deleted {{name}}',
      errorDeletedMsg: 'Failed to delete {{name}}',
      successUpdatedMsg: 'Successfully updated {{name}}',
      errorUpdatedMsg: 'Failed to update {{name}}'
    },
    dashboard: 'Dashboard',
    activities: {
      name: 'Product Name',
      title: 'Product Title',
      edit: 'Edit',
      confirmed: 'Product confirmed successfully',
      status: 'Status',
      list: 'Product List',
      addActivity: 'Register Product',
      addNewActivity: 'Register New Product',
      add: 'Add',
      selectSupplier: 'Select Supplier',
      inputSupplierTitle: 'Please input product name',
      copyTitle: 'Copy Product',
      selectSupplierLabel: 'Select Supplier',
      copy: 'Copy'
    },
    allotmentSettings: {
      pageTitle: 'Set Business Day & Inventory',
      title: 'Set Inventory',
      management: 'Register Inventory',
      poolId: 'Manage ID',
      supplierId: 'Supplier Name',
      name: 'Inventory Management Name',
      sharedAllotment: 'Shared Inventory',
      inputAllotmentName: 'Please enter the inventory management name',
      leaveCurrentPageConfirm:
        'The inventory settings are still being edited. Are you sure you want to leave this page?',
      leaveCurrentPage: 'Leave this page',
      continueEditing: 'Continue editing',
      edit: {
        name: 'Inventory',
        createTitle: 'Register new inventory',
        copyTitle: 'Copy Inventory'
      }
    },
    backendSettings: {
      title: 'Backend Inventory Settings',
      newTitle: 'New Backend Inventory Settings',
      management: 'Inventory Registration',
      poolId: 'Management ID',
      supplierId: 'Supplier Name',
      name: 'Management Name',
      confirm: 'Confirm',
      state: 'State',
      status: 'Status',
      lastExecution: 'Last Updated',
      nextExecution: 'Next Update',
      sharedAllotment: 'Shared Inventory',
      jobStatus: {
        success: 'Success',
        failed: 'Failed'
      }
    },
    activity: {
      log: {
        logHistory: 'Product Edit History',
        priceHistory: 'Price Group Edit History',
        chooseLog: 'Select Edit History',
        chooseItemLabel: 'Plan {{index}} Edit History',
        html: 'HTML Log',
        by: 'by'
      },
      memo: {
        title: 'Edit Memos',
        tips: 'The edit memo is for record-keeping during product editing and will not be displayed on the agency or direct sales site.',
        successUpdated: 'Memo Saved'
      },
      tag: {
        title: 'Edit Tags',
        tips: 'The edit tags are for record-keeping during product editing and will not be displayed on the agency or direct sales site.'
      },
      paymentCollector: 'Payment Collector',
      productDescription: 'Product Description',
      priceSetting: 'Currency Settings',
      autoPublic: 'Auto-publication settings',
      autoPublicGroup: 'Target group selection',
      imageSetting: 'Image Settings',
      participantNotice:
        'Pre-Participation / Participation Notices (Including Inclusions)',
      agentGroupName: 'Price Group Name',
      agentSaved: 'Agent Saved',
      agentSetting: 'Price Group Settings',
      addAgentGroup: 'Add Price Group',
      agentTips:
        '※Price groups are linked with price settings per agency, and an agency cannot be added to multiple groups.\nPrice Group: To link one type of price setting per agency, an agency cannot be added to multiple groups.',
      comment: 'Comment',
      publishedApplied: 'Applied for Publication',
      rejectApplied: 'Returned',
      approved: 'Approved',
      published: 'Published',
      publish: 'Publish',
      reject: 'Return the application',
      approve: 'Approve Publication',
      submit: 'Apply for Publication',
      save: 'Save',
      preview: 'Preview',
      applyForPublish: 'Apply for Product Publication',
      confirmReject: 'Return Product Publication',
      confirmApprovalOfProduct: 'Approve Product Publication',
      productContentConfirmed: 'Product details confirmed.',
      confirmForPublishingProducts: 'Product publication confirmation',
      productPublishedConfirmed: 'Product publication approved.',
      productSaved: 'Product saved',
      languageSetting: 'Multilingual settings',
      languageSelection: 'Language selection',
      productTitle: 'Product name (voucher title)',
      origin: 'Departure location',
      destination: 'Destination',
      checkinPoints: 'Meeting point',
      checkoutPoints: 'Dismissal location',
      pickupLocation: 'Pickup location',
      dropoffLocations: 'Drop-off location',
      cancellationPolicy: 'Cancellation policy',
      faqSetting: 'FAQ settings',
      chosenLanguage: 'Displayed languages',
      timeZone: 'Country/Region (Time Zone)',
      category: 'Category',
      useQrCode: 'QR code usage',
      qrcodeType: 'QR code type',
      qrcodeDisplayLocations: 'QR Code Display Location',
      voucherSetting: 'Voucher settings',
      voucherVersion: 'Voucher version',
      appointmentSetting: 'Reservation settings',
      appointmentNotification: 'Reservation notifications',
      useEticketItems: 'E-TICKET items',
      qrcodeTemplate: 'QR code template',
      qrcodeHeader: 'QR code header',
      qrcodeFooter: 'QR code footer',
      qrcodeGenerator: 'QR code generator',
      redemptionUrlTemplate: 'Exchange URL template',
      adminSettingSaved: 'Admin settings saved',
      allotmentSettingSaved: 'Plan settings saved',
      planSelection: 'Select a Plan',
      planId: 'Plan ID',
      systemCharge: 'System fees',
      productInfo: 'Product information',
      supplierId: 'Supplier ID',
      supplierName: 'Supplier name',
      name: 'Product name',
      editMemo: 'Editing notes',
      editTag: 'Editing tags',
      publishActivityConfirm: 'Do you want to publish this product?',
      publishActivitySuccess:
        '*After the product is published, the assigned agent can view the product information.',
      inPublish: 'Publishing',
      isActivityPublished: 'Product published',
      isActivityPublishedFailed: 'Failed to publish the product',
      contactUs:
        'We apologize for the inconvenience. Please contact the representative.',
      leaveCurrentPage: 'Leave this page',
      continueEditing: 'Continue editing',
      salesStatus: 'Product display',
      hideFromSearch: 'List display',
      salesStatusNotes:
        '*Please note that if you change the display status of the product, you will need to republish it.',
      hideFromSearchNotes:
        '*If list display is turned off, the product will not appear in search results or on the product list screen.',
      qrCodeTips:
        'For “QR code by number of people” or “Mogiri e-ticket” settings, please contact our staff.'
    },
    plan: {
      saveConfirm: 'Plan saved',
      name: 'Plan name',
      save: 'Save',
      planPublish: 'Plan display settings',
      bookingRule: 'Reservation settings',
      voucherExpiration: 'Voucher expiration date',
      expirationVisibilitySetting: 'Hide the voucher expiration date',
      visibilitySetting: {
        hideFromVoucher: 'Hide the voucher expiration datein the voucher',
        hideFromActivityDetails: 'Hide it on the web page (product details)',
        hideFromBookingDetails: 'Hide it on the web page (reservation details)'
      },
      checkinPoint: 'Meeting point',
      checkoutPoint: 'Dismissal location',
      pickupLocation: 'Pickup location',
      dropoffLocation: 'Drop-off location',
      unitSetting: 'Unit settings',
      priceSetting: 'Pricing settings',
      bookingAgentRequiredInfo:
        'Representative information - per reservation (required)',
      bookingAgentRecommendedInfo:
        'Representative information - per reservation (optional)',
      bookingOtherRequiredInfo:
        'Additional information - per reservation (required)',
      bookingOtherRecommendedInfo:
        'Additional information - per reservation (optional)',
      participantRequiredInfo: 'User information - per participant (required)',
      participantRecommendedInfo:
        'User information - per participant (optional)',
      customBookingInfo: 'Customized reservation information',
      settingUnable: 'End date cannot be set earlier than the start date',
      description: 'Plan description',
      imageSetting: 'Image settings',
      availablePeriod: 'Publication period',
      reservationRestrict: 'Reservation quantity restrictions',
      pieces: 'pieces',
      min: 'Minimum',
      max: 'Maximum',
      tilde: '~',
      checklistLabel:
        'Precautions before and during participation (including what is included, etc.)',
      startTime: 'Start time',
      setSchedule: 'Schedule settings',
      editConfirm: 'Confirm editing',
      leave: 'Leave this page',
      continueEditing: 'Continue editing',
      code: {
        id: 'Plan ID',
        coupon: 'Hide bundle child product vouchers',
        backendId: 'Stock settings ID',
        backendIdLabel: 'Please select stock settings',
        goToSetting: 'If not set, please <0>set it here</0>.',
        backend: 'Backend information',
        backendLabel: 'Select the backend to link',
        productCode: 'Product Code',
        productCodeLabel: 'Please enter the Product Code',
        unit: 'Unit settings',
        unitType: 'Unit type',
        unitRange: 'Unit range',
        unitCode: 'Unit Code',
        startTimeTitle: 'Start time settings',
        startTime: 'Start time',
        startTimeCode: 'Start time Code',
        codeLabel: 'Please enter the Code'
      },
      planPublishNotes:
        '*If you change the display status of the plan, you will need to republish it.',
      leavePrompt:
        'The plan is still being edited. Are you sure you want to leave this page?'
    },
    login: {
      title: 'Welcome to GDS',
      email: 'Email address',
      password: 'Password',
      signIn: 'Login',
      forgotPassword: 'Forgot your password?',
      googleLogin: 'Login with Google'
    },
    password: {
      changePassword: 'Change password',
      resetPassword: 'Reset password',
      sendResetEmail: 'Send password reset email',
      oldPassword: 'Previous password',
      newPassword: 'New password',
      changePasswordSuccess: 'Password has been changed'
    }
  }
};
