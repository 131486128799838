// Generated i18n file
export default {
  unit: {
    AGE_ADULT_AND_CHILD: '大人與小孩共通（{n}-{m}）歲',
    AGE_ADULT: '大人（{n}-{m}）歲',
    AGE_YOUTH: '青少年（{n}-{m}）歲',
    AGE_CHILD: '兒童（{n}-{m}）歲',
    AGE_SENIOR: '老年（{n}-{m}）歲',
    AGE_INFANT: '幼兒（{n}-{m}）歲',
    AGE_STUDENT: '學生（{n}-{m}）歲',
    AGE_HIGH_SCHOOL: '高中生（{n}-{m}）歲',
    AGE_INTERMEDIATE_SCHOOL: '初中生（{n}-{m}）歲',
    AGE_ELEMENTALY_SCHOOL: '小學生（{n}-{m}）歲',
    AGE_DISABLED_ADULT: '殘障成人（{n}-{m}）歲',
    AGE_DISABLED_YOUTH: '殘障青少年（{n}-{m}）歲',
    AGE_DISABLED_CHILD: '殘障兒童（{n}-{m}）歲',
    AGE_SUPPORTER_ADULT: '介護者（成人）',
    AGE_SUPPORTER_CHILD: '介護者（兒童）',
    MINUTES_VEHICLES: '{n} 分鐘',
    HOURS_VEHICLES: '{n} 小時',
    DAYS_VEHICLES: '{n} 天',
    WEEKS_VEHICLES: '{n} 週',
    NIGHTS_VEHICLES: '{n} 晚',
    CHARTER_VAR: '1台（{n}-{m}）人',
    GROUP_VAR: '1組（{n}-{m}）人',
    ROOM_VAR: '1間房（{n}-{m}）人',
    BOAT_VAR: '1艘船（{n}-{m}）人',
    PET: '寵物',
    PET_CAT: '貓',
    PET_DOG: '狗',
    PET_DOG_S: '小型犬',
    PET_DOG_M: '中型犬',
    PET_DOG_L: '大型犬',
    PET_DOG_XL: '超大型犬',
    PET_OTHER: '其他寵物'
  },
  collectingType: {
    PER_PARTICIPANT: '依每位參加者',
    PER_BOOKING: '依每筆預約'
  },
  collectingTiming: {
    ON_BOOKING: '預約時需要',
    BEFORE_ACTIVITY: '活動前需要',
    OPTIONAL: '可選'
  },
  formType: {
    DATE_ONLY_PAST: '僅限過去日期',
    DATE_ONLY_FUTURE: '僅限未來日期',
    TIME: '時間',
    YES_NO: '是/否',
    DROPDOWN: '下拉選擇框',
    RADIO: '單選按鈕',
    CHECK: '勾選框',
    TEXT: '文字文本',
    TEXT_ALPHANUMERIC: '文字（字母與數字）',
    TEXT_PHONE: '電話號碼',
    TEXTAREA: '文本區域'
  },
  formatType: {
    BEFORE_ACTIVITY_FORWARD_FIXED_CLOCK:
      '活動前{D}天，現地時間{HH:MM}後取消，將收取{N}取消費用。',
    BEFORE_ACTIVITY_FORWARD_DURATION:
      '活動前{T}小時內取消，將收取{N}取消費用。',
    AFTER_BOOKING_FORWARD_DURATION:
      '訂單確認後{T}小時內取消，將收取{N}取消費用。',
    AFTER_BOOKING_FORWARD_FIXED_CLOCK:
      '訂單確認後{D}天，現地時間{HH:MM}後取消，將收取{N}取消費用。',
    BEFORE_ACTIVITY_BACKWARD_FIXED_CLOCK:
      '活動前{D}天，現地時間{HH:MM}前取消，將收取{N}取消費用。',
    BEFORE_ACTIVITY_BACKWARD_DURATION: '活動前{T}小時取消，將收取{N}取消費用。',
    AFTER_BOOKING_BACKWARD_DURATION:
      '訂單確認後{T}小時內取消，將收取{N}取消費用。',
    AFTER_BOOKING_BACKWARD_FIXED_CLOCK:
      '訂單確認後{D}天，現地時間{HH:MM}前取消，將收取{N}取消費用。'
  },
  confirmationType: {
    FREE_SALE: '參加日指定（自由售票）',
    FREE_SALE_OPEN_DATE: '不指定日期（自由售票）',
    INVENTORY: '參加日指定（實時庫存）',
    REQUEST: '需要指定日期（要求）'
  },
  voucherType: {
    NONE: '不需要',
    COLLECTIVE: 'QR Code一單一碼',
    INDIVIDUAL: 'QR Code一人一碼'
  },
  voucherVersion: {
    VOUCHER_VERSION_V1: 'V1',
    VOUCHER_VERSION_V2: 'V2'
  },
  qrcodeType: {
    QRCODE: 'QRCODE',
    TEXT: '文字',
    URL: 'URL',
    BARCODE_128: '條形碼128',
    BARCODE_93: '條形碼93',
    BARCODE_39: '條形碼39',
    BARCODE_25: '條形碼25'
  },
  qrcodeDisplayLocation: {
    QR_CODE_DISPLAY_LOCATION_VOUCHER: '憑證',
    QR_CODE_DISPLAY_LOCATION_ETICKET_PORTAL_SITE: '電子票顯示網站'
  },
  voucherMedium: {
    ELECTRONIC: '無需印刷（螢幕顯示）',
    PAPER: '必須印刷'
  },
  cancellationFeeType: {
    PERCENTAGE: '百分比',
    ADVANCED_PERCENTAGE: '高級百分比設定',
    FIXED: '固定費用',
    NOT_CANCELLABLE: '不可取消'
  },
  datePeriodUnit: {
    DAY: '日計算',
    MONTH: '月計算'
  },
  basePrice: {
    GROSS: '銷售價格',
    NET: '批發價格',
    SUPPLIER_NET: '供應商批發價格'
  },
  checklistType: {
    RESTRICTION: '參加限制',
    REQUIRED_ITEM_TO_BRING_AND_ATTIRE: '參加所需的服裝及物品',
    REQUIRED_OTHER: '參加時其他必要事項',
    REQUIRED_ADDITIONAL_NOTE: '備註和其他',
    NICE_TO_HAVE_ITEM_TO_BRING_AND_ATTIRE: '建議攜帶的物品和服裝',
    NICE_TO_HAVE_OTHER: '其他建議物品',
    INCLUDED: '包含項目'
  },
  week: {
    SUNDAY: '星期日',
    MONDAY: '星期一',
    TUESDAY: '星期二',
    WEDNESDAY: '星期三',
    THURSDAY: '星期四',
    FRIDAY: '星期五',
    SATURDAY: '星期六'
  },
  category: {
    RAILWAY_TICKET: '鐵路車票',
    AIRPORT_TRANSPORTATION: '機場接送',
    SHINKANSEN: '新幹線',
    EXPRESS_BUS: '高速巴士',
    BUS_TOUR: '巴士遊覽',
    CRUISES: '渡輪和郵輪',
    RENTAL_CAR: '租車',
    CHARTER: '包車',
    ATTRACTION: '景點（入場券）',
    THEME_PARK: '主題樂園',
    MUSEUM_GALLERY: '博物館和畫廊',
    AQUARIUM_ZOO: '水族館和動物園',
    TEMPLES_SHINES: '寺廟和神社',
    TOWER_BUILDING: '塔和觀景台',
    JAPANESE_RESTAURANT: '和食餐廳',
    WESTERN_RESTAURANT: '西餐餐廳',
    CHINESE_RESTAURANT: '中餐餐廳',
    OTHER_RESTAURANT: '其他餐廳',
    DESSERT_BEVERAGE: '甜點和飲料',
    SIGHTSEEING_TOUR: '觀光旅遊',
    MARINE_SPORTS: '海上運動',
    OUTDOOR_ACTIVITIES: '戶外活動',
    ONSEN: '溫泉',
    SPA_MASSAGE: '水療與按摩',
    JAPANESE_CULTURAL_EXPERIENCE: '日本文化體驗',
    JAPANESE_CRAFT_EXPERIENCE: '日本工藝體驗',
    JAPANESE_COOKING_EXPERIENCE: '日本料理體驗',
    OTHER_EXPERIENCE: '其他體驗',
    HOTEL: '酒店',
    RYOKAN: '旅館',
    VACATION_RENTAL: '民宿',
    BUNDLE: '套餐',
    PASS: '通行證',
    OTHERS: '其他'
  },
  bookingField: {
    PARTICIPANT_EMAIL_ADDRESS: '參加者電子郵件地址',
    HOTEL_NAME: '住宿名稱',
    HOTEL_ADDRESS: '住宿地址',
    HOTEL_RESERVATION_LAST_NAME: '住宿預約代表者姓氏',
    HOTEL_RESERVATION_FIRST_NAME: '住宿預約代表者名字',
    HOTEL_TEL: '住宿電話號碼',
    ARRIVAL_DATE: '現地抵達日期',
    ARRIVAL_TIME: '抵達時間',
    ARRIVAL_FLIGHT_NUMBER: '到達航班號碼',
    DEPARTURE_DATE: '出發日期',
    DEPARTURE_TIME: '出發時間',
    DEPARTURE_FLIGHT_NUMBER: '出發航班號碼',
    DESTINATION_EMAIL: '現地聯絡人電子郵件',
    DESTINATION_TEL: '現地聯絡人電話號碼',
    PARTICIPANT_RESIDENT_REGION: '參加者居住國/地區',
    PARTICIPANT_LANGUAGE: '參加者語言'
  },
  extendedBookingField: {
    PASSENGERS_QUANTITY: '乘客數量',
    PREFERRED_START_TIME: '希望開始時間',
    PREFERRED_PICK_UP_LOCATION: '希望接送地點',
    PREFERRED_DROP_OFF_LOCATION: '希望送達地點',
    PARTICIPANTS_QUANTITY: '參加人數',
    LUGGAGE_QUANTITY: '行李數量（總計）',
    CHILDREN_WITHOUT_SEAT_QUANTITY: '不需要座位的幼兒數量',
    CHILD_SEAT_QUANTITY_PAID: '需要兒童座椅數量（現場支付）',
    CHILD_SEAT_QUANTITY_FREE: '需要兒童座椅數量（免費）',
    PREFERRED_GUIDE_LANGUAGE_CODE: '希望的導遊語言',
    PREFERRED_DRIVER_LANGUAGE_CODE: '希望的駕駛員語言'
  },
  extendedParticipantField: {
    PASSPORT_FIRST_NAME: '參加者名字（護照上的拼音）',
    PASSPORT_LAST_NAME: '參加者姓氏（護照上的拼音）',
    PASSPORT_NATIONALITY: '國籍',
    PASSPORT_NUMBER: '護照號碼',
    DATE_OF_BIRTH: '出生日期',
    AGE: '年齡',
    GENDER: '性別',
    EYESIGHT: '視力',
    WEIGHT_KG: '體重（公斤）',
    HEIGHT_CM: '身高（公分）',
    SHOE_SIZE_CM: '鞋碼（公分）',
    CLOTHES_SIZE: '服裝尺寸',
    VEGETARIAN_MEALS: '素食需求',
    DIVING_EXPERIENCE_TIMES: '潛水經驗次數',
    RENTAL_EQUIPMENTS_FREE: '需要租借設備（免費）',
    RENTAL_EQUIPMENTS_PAY_LOCALLY: '需要租借設備（現場支付）'
  },
  templateStatus: {
    DRAFT: '草稿',
    REVIEWING: '正在審核',
    APPROVED: '已批准',
    PUBLISHED: '已公開'
  },
  universalType: {
    TRUE: '各計劃通用',
    FALSE: '各計劃不同'
  },
  datePeriod: {
    DAY: '天數',
    MONTH: '月數',
    tilde: '〜'
  },
  voucherExpirationType: {
    FROM_PURCHASE_DATE: '從購買日起',
    FROM_ACTIVITY_DATE: '從活動日期',
    FIXED_DATE: '固定日期',
    RELATIVE_FIXED_DATE: '相對固定日期'
  },
  operation: {
    CREATE: '商品已創建',
    UPDATE: '編輯',
    DELETE: '刪除',
    SUBMIT_FOR_REVIEW: '提交審核',
    REJECT: '被駁回',
    APPROVE: '批准公開申請',
    PUBLISH: '已發布'
  },
  jobState: {
    ENABLED: '成功',
    PAUSED: '停止',
    DISABLED: '無效',
    UPDATE_FAILED: '更新失敗',
    ERROR: '錯誤'
  },
  salesStatus: {
    ON: '正常',
    OFF: '已存檔'
  }
};
